import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProjectActions } from '../project/project.actions';
import { AppSpecificDataActions } from './app-specific-data.actions';

@Injectable()
export class AppSpecificDataEffects {
    constructor(private actions$: Actions) {}

    public setReportConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppSpecificDataActions.setReportConfiguration),
            switchMap(({ reportConfiguration }) => of(ProjectActions.setAppSpecificData({ data: { reportConfiguration } })))
        )
    );

    public setCalculationsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppSpecificDataActions.setCalculationsData),
            switchMap(({ calculationsData }) => of(ProjectActions.setAppSpecificData({ data: { calculationsData } })))
        )
    );

    public setEnergyPricesPageVisted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppSpecificDataActions.setEnergyPricesPageVisited),
            switchMap(({ isEnergyPricesPageVisited }) => of(ProjectActions.setAppSpecificData({ data: { isEnergyPricesPageVisited } })))
        )
    );
}
