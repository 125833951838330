import { ValidatorFn } from '@angular/forms';
import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { QuestionOptions } from '@nx-customer-apps/shared/interfaces';

export class QuestionBase<T> {
    public value: T | undefined;
    public key: string;
    public label: string;
    public validators: ValidatorFn[];
    public order: number;
    public controlType: ControlTypes;
    public type: string;
    public styles: string;
    public question: string;
    public description: string;
    public defaultErrorMessage: string;
    public descriptionList: string[] | undefined;
    public bottomDescription: string;
    public img: string;
    public disabled: boolean;
    public hidden: boolean;
    public tooltip: string;
    public content: any | undefined;

    constructor(options: QuestionOptions<T> = { key: 'question' }) {
        this.value = options.value;
        this.key = options.key;
        this.label = options.label || '';
        this.validators = options.validators || [];
        this.order = options.order || 1;
        this.controlType = options.controlType || ControlTypes.Textbox;
        this.type = options.type || '';
        this.styles = options.styles || '';
        this.question = options.question || '';
        this.description = options.description || '';
        this.defaultErrorMessage = options.defaultErrorMessage || '';
        this.descriptionList = options.descriptionList;
        this.bottomDescription = options.bottomDescription || '';
        this.img = options.img = '';
        this.disabled = options.disabled || false;
        this.hidden = options.hidden || false;
        this.tooltip = options.tooltip || '';
        this.content = options.content;
    }
}
