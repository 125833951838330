<ng-container [formGroup]="form">
    <mat-radio-group
        [formControlName]="question.key"
        appearance="outline"
        class="ca-radio-group"
        [id]="question.key"
        [attr.data-testid]="question.key"
        [ngClass]="question.styles"
    >
        <mat-radio-button
            [disableRipple]="true"
            *ngFor="let option of question.options; trackBy: trackByKey"
            [id]="question.key + (option.id || option.value)"
            [attr.data-testid]="question.key + (option.id || option.value)"
            [disabled]="option.disabled"
            [checked]="option.checked"
            [value]="option.value"
            class="d-inline-block"
            [ngClass]="{ 'ca-radio-with-image': option.img && !option.icon, 'ca-radio-with-icon': option.icon && !option.img }"
        >
            <ca-icon *ngIf="!option.img && option.icon" [icon]="option.icon" class="ca-icon-s-24-p-0-f-24"></ca-icon>
            <img *ngIf="!option.icon && option.img" [src]="option.img" [alt]="option.key" class="mb-2" />
            <span class="vp-truncate-text">{{ option.key | translate }}</span>
            <span *ngIf="option.subtext && !option.img" class="d-block radio-subtext">{{ option.subtext | translate }}</span>
        </mat-radio-button>
    </mat-radio-group>
    <div *ngIf="question.validators?.length" class="vp-subscript position-relative mt-2">
        <div class="position-absolute vp-error-message" *ngIf="isInvalid && errorMessage" @slideDown>
            <ca-form-error-message [attr.data-testid]="question.key + '-error-message'">
                {{ errorMessage | translate }}
            </ca-form-error-message>
        </div>
    </div>
</ng-container>
