import { ApiErrors } from '@nx-customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const ApiErrorsMessageDictionary: EnumDictionary<ApiErrors, string> = {
    [ApiErrors.CalculationAPI00A]: 'API_ERRORS.CALCULATION_API_ERROR_00A',
    [ApiErrors.CalculationAPI00B]: 'API_ERRORS.CALCULATION_API_ERROR_00B',
    [ApiErrors.CalculationAPI00C]: 'API_ERRORS.CALCULATION_API_ERROR_00C',
    [ApiErrors.CalculationAPI001]: 'API_ERRORS.CALCULATION_API_ERROR_001',
    [ApiErrors.CalculationAPI002]: 'API_ERRORS.CALCULATION_API_ERROR_002',
    [ApiErrors.CalculationAPI003]: 'API_ERRORS.CALCULATION_API_ERROR_003',
    [ApiErrors.CalculationAPI004]: 'API_ERRORS.CALCULATION_API_ERROR_004',
    [ApiErrors.CalculationAPI005]: 'API_ERRORS.CALCULATION_API_ERROR_005',
    [ApiErrors.CalculationAPI006]: 'API_ERRORS.CALCULATION_API_ERROR_006',
    [ApiErrors.CalculationAPI007]: 'API_ERRORS.CALCULATION_API_ERROR_007',
    [ApiErrors.CalculationAPI008]: 'API_ERRORS.CALCULATION_API_ERROR_008',
    [ApiErrors.CalculationAPI009]: 'API_ERRORS.CALCULATION_API_ERROR_009',
    [ApiErrors.CalculationAPI010]: 'API_ERRORS.CALCULATION_API_ERROR_010',
    [ApiErrors.CalculationAPI011]: 'API_ERRORS.CALCULATION_API_ERROR_011',
    [ApiErrors.CalculationAPI012]: 'API_ERRORS.CALCULATION_API_ERROR_012',
    [ApiErrors.CalculationAPI013]: 'API_ERRORS.CALCULATION_API_ERROR_013',
    [ApiErrors.CalculationAPI014]: 'API_ERRORS.CALCULATION_API_ERROR_014',
    [ApiErrors.CalculationAPI015]: 'API_ERRORS.CALCULATION_API_ERROR_015',
    [ApiErrors.CalculationAPI016]: 'API_ERRORS.CALCULATION_API_ERROR_016',
    [ApiErrors.CalculationAPI017]: 'API_ERRORS.CALCULATION_API_ERROR_017',
    [ApiErrors.CalculationAPI018]: 'API_ERRORS.CALCULATION_API_ERROR_018',
    [ApiErrors.CalculationAPI019]: 'API_ERRORS.CALCULATION_API_ERROR_019',
    [ApiErrors.CalculationAPI020]: 'API_ERRORS.CALCULATION_API_ERROR_020',
    [ApiErrors.CalculationAPI021]: 'API_ERRORS.CALCULATION_API_ERROR_021',
    [ApiErrors.CalculationAPI022]: 'API_ERRORS.CALCULATION_API_ERROR_022',
    [ApiErrors.CalculationAPI023]: 'API_ERRORS.CALCULATION_API_ERROR_023',
    [ApiErrors.CalculationAPI024]: 'API_ERRORS.CALCULATION_API_ERROR_024',
    [ApiErrors.CalculationAPI025]: 'API_ERRORS.CALCULATION_API_ERROR_025',
    [ApiErrors.CalculationAPI026]: 'API_ERRORS.CALCULATION_API_ERROR_026',
    [ApiErrors.CalculationAPI027]: 'API_ERRORS.CALCULATION_API_ERROR_027',
    [ApiErrors.CalculationAPI028]: 'API_ERRORS.CALCULATION_API_ERROR_028',
    [ApiErrors.CalculationAPI029]: 'API_ERRORS.CALCULATION_API_ERROR_029',
    [ApiErrors.SalesforceAPIUserNotFound]: 'API_ERRORS.SALESFORCE_API_USER_NOT_FOUND',
    [ApiErrors.AddressUsedInOpenedProject]: 'API_ERRORS.ADDRESS_USED_IN_OPENED_PROJECT',
    [ApiErrors.AddressUsedInClosedProject]: 'API_ERRORS.ADDRESS_USED_IN_CLOSED_PROJECT',
    [ApiErrors.SFUnableToLockRow]: 'API_ERRORS.SALESFORCE_API_ERROR_UNABLE_TO_LOCK_ROW',
    [ApiErrors.APIDocumentation]: 'API_ERRORS.API_DOCUMENTATION',
    [ApiErrors.APIEnumMappingFailed]: 'API_ERRORS.API_ENUM_MAPPING_FAILED',
    [ApiErrors.APIMappingFailed]: 'API_ERRORS.API_MAPPING_FAILED',
    [ApiErrors.GatewayDataError]: 'API_ERRORS.GATEWAY_DATA_ERROR',
    [ApiErrors.GatewayDeserializationFailed]: 'API_ERRORS.GATEWAY_DESERIALIZATION_FAILED',
    [ApiErrors.GatewayNoResponse]: 'API_ERRORS.GATEWAY_NO_RESPONSE',
    [ApiErrors.GatewayServerError]: 'API_ERRORS.GATEWAY_SERVER_ERROR',
    [ApiErrors.GatewayValidationFailed]: 'API_ERRORS.GATEWAY_VALIDATION_FAILED',
    [ApiErrors.APIInvalidIdentity]: 'API_ERRORS.API_INVALID_IDENTITY',
    [ApiErrors.APIProjectMigrationFailed]: 'API_ERRORS.API_PROJECT_MIGRATION_FAILED',
    [ApiErrors.APIUnprocessableEntity]: 'API_ERRORS.API_UNPROCESSABLE_ENTITY',
    [ApiErrors.MultipleHeatingDevicesPresent]: 'API_ERRORS.MULTIPLE_HEATING_DEVICES_PRESENT',
    [ApiErrors.NoGasDeviceDetected]: 'API_ERRORS.NO_GAS_DEVICE_DETECTED',
    [ApiErrors.GatewayDoesNotExist]: 'API_ERRORS.GATEWAY_DOES_NOT_EXIST',
    [ApiErrors.InvalidFlowTemperature]: 'API_ERRORS.INVALID_FLOW_TEMPERATURE',
    [ApiErrors.BoilerMaterialNumberDoesNotExist]: 'API_ERRORS.BOILER_MATERIAL_NUMBER_DOES_NOT_EXIST',
    [ApiErrors.Generic]: 'COMMON.ERRORS.SOMETHING_WRONG'
};
