import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, map, catchError, tap, delay, withLatestFrom, mergeMap } from 'rxjs/operators';
import { EMPTY, iif, Observable, of } from 'rxjs';
import {
    CalculationsControllerService,
    CreateProjectRequestPost,
    ProjectResponseGetById,
    ProjectsControllerService,
    ReportsControllerService,
    UpdateProjectRequestPartial
} from '@nx-customer-apps/api-planning-projects';
import { ApiErrors, ApplicationId, ExternalTool, RoutePaths, RouterLinks } from '@nx-customer-apps/shared/enums';
import { ErrorResponse } from '@nx-customer-apps/shared/interfaces';
import { getUpdatedProperties, ProjectUtils } from '@nx-customer-apps/shared/utils';
import { LodashService, SnackbarService, WindowService } from '@nx-customer-apps/shared/services';
import { ErrorService, ExternalToolUrlService } from '../../services';
import { GlobalSpinnerService } from '../../core';
import { SettingsStore } from '../settings/settings.store';
import { AppSpecificDataActions } from '../app-specific-data/app-specific-data.actions';
import { ProjectAccessStore } from '../project-access/project-access.store';
import { ProjectActions } from './project.actions';
import { ProjectStore } from './project.store';

@Injectable()
export class ProjectEffects {
    public getProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.LOADING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.LOADING_PROJECT'
                });
            }),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            switchMap(({ action, project }) =>
                iif(
                    () => !!project,
                    of(project).pipe(tap(() => this.globalSpinnerService.reset())),
                    this.projectsControllerService.projectsControllerGetProject({
                        id: action.id,
                        detailsForApplicationId: ApplicationId.ViGuidePlanning as any
                    })
                ).pipe(
                    switchMap(response => [
                        AppSpecificDataActions.setAppSpecificData({
                            viguidePlanningSpecificData: (response as any).viguidePlanningSpecificData
                        }),
                        ProjectActions.getProjectSuccess({ project: response! })
                    ]),
                    catchError(error => {
                        this.projectAccessStore.unlockProject(action.id);
                        this.navigateTo404Page(error.error);
                        return this.handleApiErrors(error.error as ErrorResponse);
                    })
                )
            )
        )
    );

    public reloadProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.reloadProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.LOADING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.LOADING_PROJECT'
                });
            }),
            switchMap(({ id, successActionOptions, successAction }) => {
                return this.projectsControllerService
                    .projectsControllerGetProject({
                        id,
                        detailsForApplicationId: ApplicationId.ViGuidePlanning as any
                    })
                    .pipe(
                        switchMap(response => [
                            AppSpecificDataActions.setAppSpecificData({
                                viguidePlanningSpecificData: (response as any).viguidePlanningSpecificData
                            }),
                            successAction({ ...successActionOptions, project: response })
                        ]),
                        catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                    );
            })
        )
    );

    public getProjectSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.getProjectSuccess),
                tap(action => {
                    if (action.redirectUrl) {
                        this.router.navigate([action.redirectUrl]);
                    }
                })
            ),
        { dispatch: false }
    );

    public updateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.updateProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.settingsStore.languageCode$, this.projectStore.project$, (action, languageCode, project) => ({
                action,
                languageCode,
                project
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.request,
                    building: {
                        ...payload.action.request.building,
                        heatLoadCalculations: payload.action.request.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                ...action,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial, ['viguidePlanningSpecificData'])
            })),
            switchMap(({ id, body, successAction, successActionOptions }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    map(() =>
                        ProjectActions.reloadProject({
                            id,
                            successAction,
                            successActionOptions
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public setAppSpecificData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.setAppSpecificData),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            map(payload => ({
                ...payload,
                request: {
                    viguidePlanningSpecificData: {
                        ...payload.project?.viguidePlanningSpecificData,
                        data: {
                            ...payload.project?.viguidePlanningSpecificData?.data,
                            ...payload.action.data
                        }
                    }
                }
            })),
            map(({ project, request }) => ({
                id: project?.id!,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial, ['viguidePlanningSpecificData'])
            })),
            switchMap(({ id, body }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    map(() =>
                        AppSpecificDataActions.setAppSpecificData({
                            viguidePlanningSpecificData: body.viguidePlanningSpecificData!
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public migrationVerificationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.migrationVerificationSuccess),
                tap(({ callback, project }) => {
                    if (callback) {
                        callback();
                        return;
                    }
                    this.router.navigate([`${RouterLinks.Project}${project.id}`]);
                })
            ),
        { dispatch: false }
    );

    public saveHeatPumpIntermediarySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.saveHeatPumpIntermediarySuccess),
                delay(0),
                tap(({ project, variantId }) => {
                    this.globalSpinnerService.next({
                        isShown: true,
                        loadingText: 'LOADER.TEXT.LOADING_PROJECT',
                        loadingSubtext: 'LOADER.SUBTEXT.LOADING_PROJECT'
                    });
                    this.externalToolUrlService.redirectToExternalToolByProjectId(ExternalTool.HeatPumpPlanner, {
                        projectId: project.id,
                        variantId,
                        navigateTo: 'system',
                        countryCode: project.countryCode
                    });
                })
            ),
        { dispatch: false }
    );

    public saveHeatPumpIntermediary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.saveHeatPumpIntermediary),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.settingsStore.languageCode$, this.projectStore.project$, (action, languageCode, project) => ({
                action,
                languageCode,
                project
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.request,
                    building: {
                        ...payload.action.request.building,
                        heatLoadCalculations: payload.action.request.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                ...action,
                project,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial, ['household'])
            })),
            switchMap(({ project, variantId, body }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id: project!.id!, body }).pipe(
                    delay(0),
                    map(() =>
                        ProjectActions.reloadProject({
                            id: project!.id!,
                            successAction: ProjectActions.saveHeatPumpIntermediarySuccess,
                            successActionOptions: { variantId }
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public saveHeatloadSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.saveHeatloadSuccess),
                tap(({ redirectUrl }) => {
                    this.router.navigate([redirectUrl]);
                })
            ),
        { dispatch: false }
    );

    public selectHeatloadSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.selectHeatloadSuccess),
                tap(() => {
                    const message = this.translate.instant('SNACKBAR.HEATLOAD_SELECTION_CHANGED');
                    this.snackbarService.showInfo(message);
                })
            ),
        { dispatch: false }
    );

    public saveHeatload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.saveHeatload),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.projectStore.project$, this.settingsStore.languageCode$, (action, project, languageCode) => ({
                action,
                project,
                languageCode
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.projectPatch,
                    building: {
                        ...payload.action.projectPatch.building,
                        heatLoadCalculations: payload.action.projectPatch.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                redirectUrl: action.redirectUrl,
                id: project?.id!,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial, [
                    'jambWallHeight',
                    'slope1',
                    'slope2',
                    's1',
                    'height1',
                    'height2'
                ])
            })),
            switchMap(({ body, redirectUrl, id }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    map(() =>
                        ProjectActions.reloadProject({
                            id,
                            successActionOptions: { redirectUrl },
                            successAction: ProjectActions.saveHeatloadSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public savePhotovoltaicIntermediarySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.savePhotovoltaicIntermediarySuccess),
                withLatestFrom(this.projectStore.project$, (action, project) => ({ ...action, project })),
                tap(({ project, variantId }) => {
                    this.externalToolUrlService.redirectToExternalToolByProjectId(ExternalTool.PhotovoltaicPlanner, {
                        projectId: project!.id,
                        variantId,
                        navigateTo: 'system',
                        countryCode: project!.countryCode
                    });
                })
            ),
        { dispatch: false }
    );

    public savePhotovoltaicIntermediary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.savePhotovoltaicIntermediary),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            withLatestFrom(this.settingsStore.languageCode$, this.projectStore.project$, (action, languageCode, project) => ({
                action,
                languageCode,
                project
            })),
            map(payload => ({
                ...payload,
                request: {
                    ...payload.action.request,
                    building: {
                        ...payload.action.request.building,
                        heatLoadCalculations: payload.action.request.building?.heatLoadCalculations?.map(heatLoad =>
                            LodashService.omit(heatLoad, ['isSelected'])
                        )
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(({ action, project, request }) => ({
                variantId: action.variantId,
                projectId: project!.id!,
                body: getUpdatedProperties(project!, request as UpdateProjectRequestPartial)
            })),
            switchMap(({ variantId, projectId, body }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id: projectId, body }).pipe(
                    map(() => ProjectActions.savePhotovoltaicIntermediarySuccess({ variantId })),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public createProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.createProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.SAVING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.SAVING_PROJECT'
                });
            }),
            switchMap(({ request }) =>
                this.projectsControllerService.projectsControllerCreateProject({ body: request as CreateProjectRequestPost }).pipe(
                    delay(0),
                    map(project =>
                        ProjectActions.reloadProject({
                            id: project.id!,
                            successActionOptions: { redirectUrl: `${RouterLinks.Project}${project.id}` },
                            successAction: ProjectActions.getProjectSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public beforeUpdateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.beforeUpdateProject),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            switchMap(({ action, project }) =>
                iif(
                    () =>
                        !LodashService.isNil(project?.building.heatLoadCalculations?.[0]) &&
                        this.hasProjectTypeChanged(project, action.request),
                    of(
                        // Remove heat load calculations and update project
                        ProjectActions.updateProject({
                            id: action.id,
                            request: {
                                ...action.request,
                                building: {
                                    ...action.request.building,
                                    heatLoadCalculations: []
                                }
                            },
                            successAction: ProjectActions.getProjectSuccess,
                            successActionOptions: { redirectUrl: action.redirectUrl }
                        })
                    ).pipe(
                        tap(() => {
                            const messageKey =
                                project?.building.heatLoadCalculations?.length! > 1
                                    ? 'SNACKBAR.HEATLOADS_REMOVED'
                                    : 'SNACKBAR.HEATLOAD_REMOVED';
                            const message = this.translate.instant(messageKey);
                            this.snackbarService.showInfo(message, { duration: 5000 });
                        })
                    ),
                    of(
                        ProjectActions.updateProject({
                            id: action.id,
                            request: action.request,
                            successAction: ProjectActions.getProjectSuccess,
                            successActionOptions: { redirectUrl: action.redirectUrl }
                        })
                    )
                )
            )
        )
    );

    public deleteHeatload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.deleteHeatload),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.DELETING_HEATLAOD',
                    loadingSubtext: 'LOADER.SUBTEXT.DELETING_HEATLAOD'
                });
            }),
            withLatestFrom(this.projectStore.project$, this.settingsStore.languageCode$, (action, project, languageCode) => ({
                action,
                project,
                languageCode
            })),
            map(payload => ({
                ...payload,
                request: {
                    building: {
                        heatLoadCalculations: payload.project?.building?.heatLoadCalculations
                            ?.map(heatLoad => LodashService.omit(heatLoad, ['isSelected']))
                            ?.filter((heatLoad: any) => heatLoad.method !== payload.action.method)
                    },
                    languageCode: payload.languageCode
                }
            })),
            map(payload => ({
                ...payload,
                id: payload.project?.id!,
                body: getUpdatedProperties(payload.project!, payload.request as UpdateProjectRequestPartial)
            })),
            switchMap(({ action, body, project, id }) =>
                this.projectsControllerService.projectsControllerPatchProject({ id, body }).pipe(
                    delay(0),
                    tap(() => {
                        const messageKey = 'SNACKBAR.HEATLOAD_DELETED';
                        const message = this.translate.instant(messageKey);
                        if (project?.building.heatLoadCalculations?.length! > 1) {
                            const calculation = project?.building.heatLoadCalculations?.find(heatload => heatload.method === action.method);
                            if (!calculation?.isSelected) {
                                this.snackbarService.showInfo(message);
                            }
                        } else if (!ProjectUtils.hasPlanningsWithProductsAmongAllVariants(project!)) {
                            this.snackbarService.showInfo(message);
                        }
                    }),
                    map(() =>
                        ProjectActions.reloadProject({
                            id,
                            successAction: ProjectActions.deleteHeatloadSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public selectHeatload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.selectHeatload),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.SELECTING_HEATLAOD',
                    loadingSubtext: 'LOADER.SUBTEXT.SELECTING_HEATLAOD'
                });
            }),
            switchMap(action =>
                this.calculationsControllerService.calculationsControllerSelect({ body: action.request }).pipe(
                    delay(0),
                    map(() =>
                        ProjectActions.reloadProject({
                            id: action.request.projectId,
                            successAction: ProjectActions.selectHeatloadSuccess
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public closeProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.closeProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.CLOSING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.CLOSING_PROJECT'
                });
            }),
            switchMap(({ projectId }) =>
                this.projectsControllerService.projectsControllerFinalizeProject({ id: projectId }).pipe(
                    map(response =>
                        ProjectActions.closeProjectSuccess({
                            response
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public completeProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.completeProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.COMPLETING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.COMPLETING_PROJECT'
                });
            }),
            switchMap(({ projectId }) =>
                this.projectsControllerService.projectsControllerFinalizeProject({ id: projectId }).pipe(
                    map(response =>
                        ProjectActions.completeProjectSuccess({
                            response
                        })
                    ),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public uploadProjectReportSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.uploadProjectReportSection),
            mergeMap(payload =>
                this.reportsControllerService.reportsControllerUploadSection(payload).pipe(
                    map(() => ProjectActions.uploadProjectReportSectionSuccess()),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    public pdfReportGeneration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.pdfReportGeneration),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.DOWNLOADING_REPORT',
                    loadingSubtext: 'LOADER.SUBTEXT.DOWNLOADING_REPORT'
                });
            }),
            mergeMap(payload =>
                this.reportsControllerService.reportsControllerPdfReportGeneration(payload).pipe(
                    tap((blob: any) => {
                        this.downloadPdfReport(blob, payload.projectId, payload.reportType);
                    }),
                    map(() => ProjectActions.pdfReportGenerationSuccess()),
                    catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                )
            )
        )
    );

    private downloadPdfReport(blob: Blob, projectId: string, reportType: string): void {
        const downloadUrl = this.windowService.nativeWindow.window.URL.createObjectURL(blob);
        const link = this.windowService.nativeWindow.document.createElement('a');
        link.setAttribute('href', downloadUrl);
        link.setAttribute('download', `${reportType}_${projectId}.pdf`);
        link.click();
        this.windowService.nativeWindow.window.URL.revokeObjectURL(downloadUrl);
    }

    private hasProjectTypeChanged(
        currentProject: ProjectResponseGetById | undefined,
        requestProject: UpdateProjectRequestPartial
    ): boolean {
        return !!requestProject.projectType && currentProject?.projectType !== requestProject.projectType;
    }

    private handleApiErrors(error: ErrorResponse): Observable<any> {
        this.globalSpinnerService.reset();
        const isOf409or422Status = [409, 422].includes(error.status);
        if (!isOf409or422Status) {
            return EMPTY;
        }
        this.errorService.showSnackbar(error.key as ApiErrors, { duration: 0 });
        const hasAddressCollission =
            error.key === ApiErrors.AddressUsedInOpenedProject || error.key === ApiErrors.AddressUsedInClosedProject;
        if (hasAddressCollission) {
            return of(ProjectActions.addressCollision());
        }
        return EMPTY;
    }

    private navigateTo404Page(error: ErrorResponse): void {
        if (error.status === 404) {
            this.router.navigate([RoutePaths.Error404]);
        }
    }

    constructor(
        private actions$: Actions,
        private router: Router,
        private globalSpinnerService: GlobalSpinnerService,
        private snackbarService: SnackbarService,
        private errorService: ErrorService,
        private translate: TranslateService,
        private settingsStore: SettingsStore,
        private projectsControllerService: ProjectsControllerService,
        private reportsControllerService: ReportsControllerService,
        private calculationsControllerService: CalculationsControllerService,
        private projectStore: ProjectStore,
        private projectAccessStore: ProjectAccessStore,
        private externalToolUrlService: ExternalToolUrlService,
        private windowService: WindowService
    ) {}
}
