import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { StatusSummary } from '@nx-customer-apps/shared/interfaces';
import { EnvironmentService } from './environment.service';
import { Status } from '@nx-customer-apps/shared/enums';
import { WindowService } from '@nx-customer-apps/shared/services';

@Injectable({
    providedIn: 'root'
})
export class StatusPageService {
    private readonly statusPageUrl = this.environmentService.statusPageUrl;
    private readonly servicePageUrl = this.environmentService.servicePageUrl;
    private readonly statusPageState = new BehaviorSubject<{ statusSummary: StatusSummary | null; loaded: boolean; error?: string | null }>(
        {
            statusSummary: null,
            loaded: false,
            error: null
        }
    );

    constructor(private http: HttpClient, private environmentService: EnvironmentService, private windowService: WindowService) {}

    /**
     * Get status page for ViGuide Planning
     */
    public getStatusPage(): void {
        this.http.get<StatusSummary>(this.statusPageUrl).subscribe({
            next: statusSummary => {
                if (statusSummary.status === Status.MajorOutage || statusSummary.status === Status.UnderMaintenance) {
                    this.windowService.changeHref(this.servicePageUrl);
                }
                this.statusPageState.next({ statusSummary, loaded: true });
            },
            error: err => {
                this.statusPageState.next({ statusSummary: null, loaded: true, error: err.message });
            }
        });
    }

    /**
     * Get status page state
     */
    public get state$(): Observable<{ statusSummary: StatusSummary | null; loaded: boolean; error?: string | null }> {
        return this.statusPageState.asObservable();
    }
}
