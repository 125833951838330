import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { AdvancedSearchOptions } from '@nx-customer-apps/shared/interfaces';
import { QuestionGroupBase } from './question-group-base.model';

export class AdvancedSearch extends QuestionGroupBase<any[]> {
    override controlType = ControlTypes.AdvancedSearch;
    public noResultsTitle: string;
    public noResultsDescription: string;
    constructor(options: AdvancedSearchOptions) {
        super(options);
        this.noResultsTitle = options.noResultsTitle || '';
        this.noResultsDescription = options.noResultsDescription || '';
    }
}
