export enum ApiErrors {
    CalculationAPI00A = 'CALCULATION_API_00A',
    CalculationAPI00B = 'CALCULATION_API_00B',
    CalculationAPI00C = 'CALCULATION_API_00C',
    CalculationAPI001 = 'CALCULATION_API_001',
    CalculationAPI002 = 'CALCULATION_API_002',
    CalculationAPI003 = 'CALCULATION_API_003',
    CalculationAPI004 = 'CALCULATION_API_004',
    CalculationAPI005 = 'CALCULATION_API_005',
    CalculationAPI006 = 'CALCULATION_API_006',
    CalculationAPI007 = 'CALCULATION_API_007',
    CalculationAPI008 = 'CALCULATION_API_008',
    CalculationAPI009 = 'CALCULATION_API_009',
    CalculationAPI010 = 'CALCULATION_API_010',
    CalculationAPI011 = 'CALCULATION_API_011',
    CalculationAPI012 = 'CALCULATION_API_012',
    CalculationAPI013 = 'CALCULATION_API_013',
    CalculationAPI014 = 'CALCULATION_API_014',
    CalculationAPI015 = 'CALCULATION_API_015',
    CalculationAPI016 = 'CALCULATION_API_016',
    CalculationAPI017 = 'CALCULATION_API_017',
    CalculationAPI018 = 'CALCULATION_API_018',
    CalculationAPI019 = 'CALCULATION_API_019',
    CalculationAPI020 = 'CALCULATION_API_020',
    CalculationAPI021 = 'CALCULATION_API_021',
    CalculationAPI022 = 'CALCULATION_API_022',
    CalculationAPI023 = 'CALCULATION_API_023',
    CalculationAPI024 = 'CALCULATION_API_024',
    CalculationAPI025 = 'CALCULATION_API_025',
    CalculationAPI026 = 'CALCULATION_API_026',
    CalculationAPI027 = 'CALCULATION_API_027',
    CalculationAPI028 = 'CALCULATION_API_028',
    CalculationAPI029 = 'CALCULATION_API_029',

    AddressUsedInOpenedProject = 'SALESFORCE_API_ADDRESS_USED_IN_OPENED_PROJECT',
    AddressUsedInClosedProject = 'SALESFORCE_API_ADDRESS_USED_IN_CLOSED_PROJECT',
    SFUnableToLockRow = 'SALESFORCE_API_ERROR_UNABLE_TO_LOCK_ROW',
    SalesforceAPIUserNotFound = 'SALESFORCE_API_USER_NOT_FOUND',

    APIDocumentation = 'API_DOCUMENTATION',
    APIEnumMappingFailed = 'API_ENUM_MAPPING_FAILED',
    APIMappingFailed = 'API_MAPPING_FAILED',
    GatewayDataError = 'GATEWAY_DATA_ERROR',
    GatewayDeserializationFailed = 'GATEWAY_DESERIALIZATION_FAILED',
    GatewayNoResponse = 'GATEWAY_NO_RESPONSE',
    GatewayServerError = 'GATEWAY_SERVER_ERROR',
    GatewayValidationFailed = 'GATEWAY_VALIDATION_FAILED',
    APIInvalidIdentity = 'API_INVALID_IDENTITY',
    APIProjectMigrationFailed = 'API_PROJECT_MIGRATION_FAILED',
    APIUnprocessableEntity = 'API_UNPROCESSABLE_ENTITY',
    MultipleHeatingDevicesPresent = 'DATA_LAKE_API_MULTIPLE_HEATING_DEVICES_PRESENT',
    NoGasDeviceDetected = 'DATA_LAKE_API_NO_GAS_DEVICE_DETECTED',
    GatewayDoesNotExist = 'DATA_LAKE_API_GATEWAY_DOES_NOT_EXIST',
    InvalidFlowTemperature = 'DATA_LAKE_API_INVALID_FLOW_TEMPERATURE',
    BoilerMaterialNumberDoesNotExist = 'DATA_LAKE_API_BOILER_MATERIAL_NUMBER_DOES_NOT_EXIST',
    Generic = 'SOMETHING_WENT_WRONG'
}
