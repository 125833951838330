import { createSelector } from '@ngrx/store';
import { AppState, HeatloadState } from '@nx-customer-apps/shared/interfaces';

export namespace HeatloadSelectors {
    export const heatloadState = (state: AppState) => state.heatload;

    export const getState = () => createSelector(heatloadState, (state: HeatloadState) => state);

    export const getCalculationMethod = () => createSelector(heatloadState, (state: HeatloadState) => state.calculationMethod);

    export const getDefinedValue = () => createSelector(heatloadState, (state: HeatloadState) => state.definedValue);

    export const getHeatGenerators = () => createSelector(heatloadState, (state: HeatloadState) => state.heatGenerators);

    export const getLivingArea = () => createSelector(heatloadState, (state: HeatloadState) => state.livingArea);

    export const getModernization = () => createSelector(heatloadState, (state: HeatloadState) => state.modernization);

    export const getVentilation = () => createSelector(heatloadState, (state: HeatloadState) => state.ventilation);

    export const getEnergyCertificate = () => createSelector(heatloadState, (state: HeatloadState) => state.energyCertificate);

    export const getBuildingHull = () => createSelector(heatloadState, (state: HeatloadState) => state.buildingHull);

    export const getEnergyStandards = () => createSelector(heatloadState, (state: HeatloadState) => state.energyStandards);

    export const getSelectedEnergyStandard = () => createSelector(heatloadState, (state: HeatloadState) => state.selectedEnergyStandard);

    export const getRoofType = () => createSelector(heatloadState, (state: HeatloadState) => state.roofType);

    export const getRoofDimensions = () => createSelector(heatloadState, (state: HeatloadState) => state.roofDimensions);

    export const getWindowsAndGlazing = () => createSelector(heatloadState, (state: HeatloadState) => state.windowsAndGlazing);

    export const getWindows = () => createSelector(heatloadState, (state: HeatloadState) => state.windows);

    export const getEnergySource = () => createSelector(heatloadState, (state: HeatloadState) => state.heatingEnergySource);

    export const getHeatGeneratorDetails = () => createSelector(heatloadState, (state: HeatloadState) => state.heatGeneratorDetails);

    export const getEnergyConsumption = () => createSelector(heatloadState, (state: HeatloadState) => state.energyConsumption);

    export const getHeatingDistribution = () => createSelector(heatloadState, (state: HeatloadState) => state.heatingDistribution);

    export const getHeatingSystems = () => createSelector(heatloadState, (state: HeatloadState) => state.heatingSystems);

    export const installations = () => createSelector(heatloadState, (state: HeatloadState) => state.installations);

    export const selectedInstallation = () => createSelector(heatloadState, (state: HeatloadState) => state.selectedInstallation);

    export const sameValueSubmited = () => createSelector(heatloadState, (state: HeatloadState) => state.sameValueSubmited);

    export const getModernizationsAndInsulation = () =>
        createSelector(heatloadState, (state: HeatloadState) => state.modernizationsAndInsulation);

    export const getCache = () => createSelector(heatloadState, (state: HeatloadState) => state.cache);
}
