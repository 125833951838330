import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatusSummary } from '@nx-customer-apps/shared/interfaces';

@Component({
    selector: 'vp-status-page',
    templateUrl: './status-page.component.html',
    styleUrls: ['./status-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusPageComponent {
    @Input() statusSummary: StatusSummary | null;
}
