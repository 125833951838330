<div [ngClass]="question.styles" *ngIf="control">
    <mat-checkbox [formControl]="control" class="ca-checkbox" [disableRipple]="true" [id]="question.key" [attr.data-testid]="question.key">
        {{ question.label | translate }}
    </mat-checkbox>
    <div *ngIf="question.validators?.length" class="vp-subscript position-relative mt-2">
        <div class="position-absolute vp-error-message" *ngIf="isInvalid && isTouched" @slideDown>
            <ca-form-error-message>
                {{ errorMessage | translate }}
            </ca-form-error-message>
        </div>
    </div>
</div>
