import { Injectable } from '@angular/core';
import { ProjectResponseGetById } from '@nx-customer-apps/api-planning-projects';
import { RemoteConfigParameter } from '@nx-customer-apps/shared/enums';
import { RemoteConfigService } from './remote-config.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class PitchDeckService {
    constructor(private remoteConfigService: RemoteConfigService, private authService: AuthService) {}

    public isPitchDeckAvailable(project: ProjectResponseGetById): boolean {
        const locale = `${project.languageCode}-${project.countryCode}`;
        const supportedLocales = this.remoteConfigService.config[RemoteConfigParameter.PdfConfiguratorPitchDeckByLocale].split(',');
        return (
            supportedLocales.includes(locale) &&
            (this.authService.isInstaller() || this.authService.isEmployee() || this.authService.isSystemProfi())
        );
    }
}
