<ng-container [formGroup]="form">
    <span *ngIf="question.paragraph" class="me-2"> {{ question.paragraph | translate }}</span>
    <mat-form-field class="ca-form-field" [hideRequiredMarker]="true" appearance="outline" [ngClass]="question | formFieldStyles">
        <mat-label *ngIf="question.label" [attr.for]="question.key">{{ question.label | translate }}</mat-label>
        <input
            [formControlName]="question.key"
            [placeholder]="question.placeholder | translate"
            [id]="question.key"
            [attr.data-testid]="question.key"
            [type]="question.type"
            [mask]="question.mask.pattern"
            [patterns]="$any(question).mask?.customPatterns"
            (input)="onInput($event)"
            (blur)="onBlur()"
            [decimalMarker]="$any(question).mask?.decimalMarker"
            [separatorLimit]="$any(question).mask?.separatorLimit"
            [dropSpecialCharacters]="
                ($any(question).mask?.dropSpecialCharacters | isNil) ? true : $any(question).mask?.dropSpecialCharacters
            "
            [suffix]="question.suffix ? ' ' + (question.suffix | translate) : ''"
            matInput
        />
        <mat-hint *ngIf="hint"> {{ hint | translate }}</mat-hint>
        <mat-hint *ngIf="question.permanentHint"> {{ question.permanentHint | translate }}</mat-hint>
        <mat-hint *ngIf="question.ifConditionalHint && question.conditionalHint"> {{ question.conditionalHint | translate }}</mat-hint>
        <mat-error *ngIf="isInvalid && errorMessage">
            <ca-form-error-message [attr.data-testid]="question.key + '-error-message'">
                {{ errorMessage | translate }}
            </ca-form-error-message>
        </mat-error>
    </mat-form-field>
    <span class="ms-1" *ngIf="question.paragraph">.</span>
</ng-container>
