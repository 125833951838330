import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { HeatloadService } from '../../heatload/services/heatload.service';
import { catchError, switchMap, filter, map, EMPTY, auditTime, withLatestFrom, tap, merge } from 'rxjs';
import { HeatloadActions } from './heatload.actions';
import { EnergySource, HeatGeneratorType, HeatLoadCalculationMethod, ProjectType } from '@nx-customer-apps/shared/enums';
import { HeatloadStore } from './heatload.store';
import { ModernizationsAndInsulationFormValue } from '@nx-customer-apps/shared/interfaces';
import { DeviceSelectionService, HeatGeneratorDetailsService } from '../../heatload/services';
import { ProjectStore } from '../project/project.store';
import { ProjectsService } from '../../services';

@Injectable()
export class HeatloadEffects {
    constructor(
        private actions$: Actions,
        private heatloadService: HeatloadService,
        private heatloadStore: HeatloadStore,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private heatGeneratorDetails: HeatGeneratorDetailsService,
        private deviceSelection: DeviceSelectionService
    ) {}

    public getHeatGenerators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.submitCalculationMethod),
            auditTime(10),
            filter(formValue => formValue.methods.standardMethod === HeatLoadCalculationMethod.Consumption),
            switchMap(() =>
                this.heatloadService.getHeatGenerators().pipe(
                    map(generators => HeatloadActions.getHeatGeneratorsSuccess({ generators })),
                    catchError(error => EMPTY)
                )
            )
        )
    );

    public getConnectedDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.getInstallations),
            auditTime(10),
            switchMap(action =>
                this.heatloadService.getConnectedDevices(action.filter).pipe(
                    map(installations => HeatloadActions.getInstallationsSuccess({ installations })),
                    catchError(error => EMPTY)
                )
            )
        )
    );

    public getWindows$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.submitModernizationsAndInsulation),
            auditTime(10),
            switchMap(formValue =>
                this.heatloadService
                    .getWindows(
                        (formValue as ModernizationsAndInsulationFormValue).windowsConstructionYear,
                        (formValue as ModernizationsAndInsulationFormValue).buildingConstructionYear
                    )
                    .pipe(catchError(error => EMPTY))
            ),
            map(windows => HeatloadActions.getWindowsSuccess({ windows }))
        )
    );

    public getEnergyStandards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.submitLivingArea),
            auditTime(10),
            withLatestFrom(this.projectStore.project$, (formValue, project) => ({ formValue, project })),
            filter(({ project }) => this.projectsService.isNewConstruction(project!.projectType as ProjectType)),
            switchMap(() => this.heatloadService.getEnergyStandards().pipe(catchError(error => EMPTY))),
            map(standards => HeatloadActions.getEnergyStandardsSuccess({ standards }))
        )
    );

    public getHeatingSystems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.getHeatingSystems),
            withLatestFrom(this.heatloadStore.energySource$, (action, energySourceState) => ({ action, energySourceState })),
            switchMap(({ action, energySourceState }) =>
                this.heatloadService
                    .getHeatingSystems(
                        energySourceState!.selectedEnergySource!.type as EnergySource,
                        +action.installationYear,
                        energySourceState!.selectedHeatGenerator as HeatGeneratorType
                    )
                    .pipe(catchError(error => EMPTY))
            ),
            map(heatingSystems => HeatloadActions.getHeatingSystemsSuccess({ heatingSystems }))
        )
    );

    /**
     * Reseting the installation year "prefill finished" state to make the prefilling work again when resubmiting the energy source accordion.
     */
    public resetHeatGeneratorDetailsPrefillState$ = createEffect(
        () =>
            merge(this.heatloadStore.heatGeneratorDetails$, this.heatloadStore.energySource$.pipe(filter(Boolean))).pipe(
                tap(() => this.heatGeneratorDetails.toggleIsConstructionYearPrefillFinished(false))
            ),
        { dispatch: false }
    );

    /**
     * Reseting the installation filter "prefill finished" state to make the prefilling work again when resubmiting the energy source accordion.
     */
    public resetDeviceSelectionPrefillState$ = createEffect(
        () =>
            merge(this.heatloadStore.selectedInstallation$, this.heatloadStore.calculationMethod$.pipe(filter(Boolean))).pipe(
                tap(() => this.deviceSelection.toggleIsInstallationFilterPrefillFinished(false))
            ),
        { dispatch: false }
    );
}
