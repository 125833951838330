/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProjectResponseGetById } from '../../models/project-response-get-by-id';

export interface ProjectsControllerGetProject$Params {

/**
 * Project id.
 */
  id: string;

/**
 * <p>
 *             Optional query parameter(s): the planning application IDs list, used to get additional planning details for given applications.
 *             By providing these optional query parameters, given application planning will be extended by two more
 *             detail fields called <code>applicationSpecific</code> and
 *             <code>electricityDemandHourly</code>.
 *         </p>
 *         <p>
 *             The planning for provided application ID may not exist in a project, it will not throw any
 *             error; we can provide more of <code>detailsForApplicationId</code> params, even for non-existing plannings.</i>
 *         </p>
 *         <p>
 *             Please be aware that more applications need more background requests to get the details,
 *             so this call may take longer in total for a bigger number of <code>detailsForApplicationId</code> query parameters.
 *         </p>
 */
  detailsForApplicationId?: Array<'HEAT_PUMP_PLANNER' | 'PHOTOVOLTAIC_PLANNER' | 'VENTILATION_PLANNER' | 'VIGUIDE_PLANNING' | 'HOME_PLANNER' | 'BOILER_PLANNER'>;
}

export function projectsControllerGetProject(http: HttpClient, rootUrl: string, params: ProjectsControllerGetProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectResponseGetById>> {
  const rb = new RequestBuilder(rootUrl, projectsControllerGetProject.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('detailsForApplicationId', params.detailsForApplicationId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProjectResponseGetById>;
    })
  );
}

projectsControllerGetProject.PATH = '/planning-projects/v1-preview/projects/{id}';
