export enum SuccessMessages {
    BuildingHull = 'HEATLOAD.CALCULATION_METHOD.SUCCESS_MESSAGES.BUILDING_HULL',
    LivingArea = 'HEATLOAD.CALCULATION_METHOD.SUCCESS_MESSAGES.LIVING_AREA',
    EnergyCertificate = 'HEATLOAD.CALCULATION_METHOD.SUCCESS_MESSAGES.ENERGY_CERTIFICATE',
    Installation = 'HEATLOAD.CALCULATION_METHOD.SUCCESS_MESSAGES.INSTALLATION',
    ByConsumption = 'HEATLOAD.CALCULATION_METHOD.SUCCESS_MESSAGES.CONSUMPTION',
    LivingAreaSize = 'HEATLOAD.LIVING_AREA.SUCCESS_MESSAGE',
    HeatingEnergySource = 'HEATLOAD.ENERGY_SOURCE.SOURCES',
    StepCompleted = 'SUCCESS_MESSAGES.STEP_COMPLETED',
    Yes = 'HEATLOAD.VENTILATION.SUCCESS_MESSAGES.YES',
    No = 'HEATLOAD.VENTILATION.SUCCESS_MESSAGES.NO'
}
