import { EnergySource } from '@nx-customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const energySourceToPhraseKey: Partial<EnumDictionary<EnergySource, string>> = {
    [EnergySource.Pellet]: 'COMMON.ENERGY_SOURCES.PELLET',
    [EnergySource.Softwood]: 'COMMON.ENERGY_SOURCES.SOFTWOOD',
    [EnergySource.Hardwood]: 'COMMON.ENERGY_SOURCES.HARDWOOD',
    [EnergySource.Gas]: 'COMMON.ENERGY_SOURCES.GAS',
    [EnergySource.Methane]: 'COMMON.ENERGY_SOURCES.METHANE',
    [EnergySource.Hydrogen]: 'COMMON.ENERGY_SOURCES.HYDROGEN'
};
