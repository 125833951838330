<ng-container [formGroup]="form">
    <div [formGroupName]="question.groupName" [ngClass]="question.styles">
        <mat-radio-group
            class="ca-radio-group"
            [formControlName]="question.questions[0].key"
            appearance="outline"
            [id]="question.questions[0].key"
        >
            <mat-radio-button
                [disableRipple]="true"
                *ngFor="let option of (question.questions[0] | as : TYPES.RadioQuestion).options"
                [value]="option.value"
                [disabled]="option.disabled || question.questions[0].disabled"
                [id]="question.questions[0].key + option.value"
                [attr.data-testid]="question.questions[0].key + option.value"
                [ngClass]="{
                    'ca-custom-radio-error': isGroupInvalid && isGroupTouched && !option.disabled,
                    'ca-radio-disabled': option.disabled || question.questions[0].disabled
                }"
            >
                <ca-icon *ngIf="option.icon" [icon]="option.icon" class=""></ca-icon
                ><span>{{ option.key | translate }}</span></mat-radio-button
            >
            <mat-form-field
                [hideRequiredMarker]="true"
                class="ca-form-field"
                appearance="outline"
                [class.with-label]="question.questions[1].label | translate"
                [class.mat-form-field-invalid]="(isGroupInvalid && isGroupTouched) || (isTextInvalid && isTextTouched)"
                [class.with-wrapped-error-message]="question.questions[1].validators.length && !(isGroupInvalid && isGroupTouched)"
                [class.ca-form-field-value]="question.questions[1].value && !anyRadioActive"
                [ngClass]="question.questions[1].styles"
            >
                <mat-label *ngIf="question.questions[1].label" [attr.for]="question.questions[1].key">{{
                    question.questions[1].label | translate
                }}</mat-label>
                <input
                    [placeholder]="question.questions[1].placeholder | translate"
                    [formControlName]="question.questions[1].key"
                    [id]="question.questions[1].key"
                    [attr.data-testid]="question.questions[1].key"
                    [type]="question.questions[1].type"
                    [mask]="(question.questions[1] | as : TYPES.TextboxQuestion).mask.pattern"
                    [patterns]="$any(question.questions[1]).mask?.customPatterns"
                    [decimalMarker]="$any(question.questions[1]).mask?.decimalMarker"
                    [separatorLimit]="$any(question.questions[1]).mask?.separatorLimit"
                    (focus)="onTextFieldFocus()"
                    [suffix]="' ' + (question.questions[1].suffix | translate)"
                    matInput
                />
                <mat-hint *ngIf="question.questions[1].permanentHint && !(isGroupInvalid && isGroupTouched)" @slideDown>
                    {{ question.questions[1].permanentHint | translate }}</mat-hint
                >
                <mat-error *ngIf="isTextTouched && !(isGroupInvalid && isGroupTouched)">
                    <ca-form-error-message>
                        {{ textErrorMessage | translate }}
                    </ca-form-error-message>
                </mat-error>
            </mat-form-field>
        </mat-radio-group>
        <div *ngIf="question.validators?.length" class="vp-subscript position-relative mt-2">
            <div class="position-absolute vp-error-message" *ngIf="isGroupInvalid && isGroupTouched" @slideDown>
                <ca-form-error-message>
                    {{ groupErrorMessage | translate }}
                </ca-form-error-message>
            </div>
        </div>
    </div>
</ng-container>
