<vp-global-spinner data-testid="global-spinner"></vp-global-spinner>
@if(authenticated | async) {
<vp-toolbar class="app-header" [isAuthenticated]="authenticated" (logoutClick)="logout()"></vp-toolbar>
@if(statusSummaryState$ | async; as state) { @if(state.loaded && !state.error){
<vp-status-page [statusSummary]="state.statusSummary"></vp-status-page>
} } }
<div class="app-content" [class.vp-auth]="authenticated | async">
    <router-outlet></router-outlet>
</div>
<vp-footer class="app-footer"></vp-footer>
