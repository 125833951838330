<ng-container [formGroup]="form">
    <div [formGroupName]="question.groupName" class="d-flex align-items-start flex-wrap mb-3 vp-w-100" [ngClass]="question.styles">
        <ng-container
            *ngFor="let questionPart of question.questions; let isLast = last; let isFirst = first; let index = index"
            [ngSwitch]="questionPart.controlType"
        >
            <vp-textbox
                *ngIf="questionPart.controlType === CONTROL_TYPES.Textbox"
                [form]="formGroup"
                [question]="questionPart"
                [ngClass]="'text-control-' + index"
            >
            </vp-textbox>
            <!-- <div class="vp-subscript position-relative">
                <div class="position-absolute vp-error-message">
                    <ca-form-error-message>
                        {{ errorMessage | translate }}
                    </ca-form-error-message>
                </div>
            </div> -->
        </ng-container>
        <button
            type="button"
            mat-stroked-button
            data-testid="apply-advanced-search-filter"
            class="ca-button ca-button-s ms-3 mt-2"
            (click)="search()"
        >
            <span> {{ 'COMMON.BUTTONS.APPLY' | translate }} </span>
        </button>
    </div>
    @if( resultQuestion?.options?.length){
    <h5 class="mb-3">{{ resultQuestion!.question | translate }}</h5>
    <vp-radio [form]="formGroup" [question]="resultQuestion!"></vp-radio>
    } @else if (!resultQuestion?.options?.length && formGroup.touched && !loading) {
    <h5 class="mb-3">{{ question!.noResultsTitle | translate }}</h5>
    <span class="mat-body-medium vp-text-grey">{{ question!.noResultsDescription | translate }}</span>
    }
</ng-container>
