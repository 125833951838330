/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { BuildingsControllerService } from './services/buildings-controller.service';
import { CalculationsControllerService } from './services/calculations-controller.service';
import { DataControllerService } from './services/data-controller.service';
import { DevicesControllerService } from './services/devices-controller.service';
import { EconomicsControllerService } from './services/economics-controller.service';
import { InfoControllerService } from './services/info-controller.service';
import { LocationsControllerService } from './services/locations-controller.service';
import { OrdersControllerService } from './services/orders-controller.service';
import { ParamsControllerService } from './services/params-controller.service';
import { PartnersControllerService } from './services/partners-controller.service';
import { PlanningsControllerService } from './services/plannings-controller.service';
import { ProductsControllerService } from './services/products-controller.service';
import { ProjectsControllerService } from './services/projects-controller.service';
import { QuotesControllerService } from './services/quotes-controller.service';
import { ReportsControllerService } from './services/reports-controller.service';
import { SimulationsControllerService } from './services/simulations-controller.service';
import { TranslationsControllerService } from './services/translations-controller.service';
import { UsersControllerService } from './services/users-controller.service';
import { VariantsControllerService } from './services/variants-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BuildingsControllerService,
    CalculationsControllerService,
    DataControllerService,
    DevicesControllerService,
    EconomicsControllerService,
    InfoControllerService,
    LocationsControllerService,
    OrdersControllerService,
    ParamsControllerService,
    PartnersControllerService,
    PlanningsControllerService,
    ProductsControllerService,
    ProjectsControllerService,
    QuotesControllerService,
    ReportsControllerService,
    SimulationsControllerService,
    TranslationsControllerService,
    UsersControllerService,
    VariantsControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
