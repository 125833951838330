import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    RendererFactory2,
    ViewChildren
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { AdvancedSearch, joinFilters, RadioQuestion } from '@nx-customer-apps/shared/utils';
import { ViewHostDirective } from '../../directives/view-host.directive';
import { DOCUMENT } from '@angular/common';
import { ControlTypes, FormKeys } from '@nx-customer-apps/shared/enums';

@Component({
    selector: 'vp-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrl: './advanced-search.component.scss'
})
export class AdvancedSearchComponent implements OnInit, OnChanges {
    @Input() question: AdvancedSearch;
    @Input() form: FormGroup;
    @Input() minimalSearchValueLength: number;
    @Output() trigger: EventEmitter<{ key: FormKeys; value: any }> = new EventEmitter();
    @ViewChildren(ViewHostDirective) optionHosts: QueryList<ViewHostDirective>;
    public loading: boolean;
    public resultQuestion: RadioQuestion | undefined;
    readonly CONTROL_TYPES: typeof ControlTypes = ControlTypes;
    private renderer: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public ngOnInit(): void {
        this.setResultQuestion();
    }

    public get formGroup(): FormGroup {
        return this.form.get(this.question.groupName) as FormGroup;
    }

    public get filterControl(): FormControl {
        return (this.formGroup.get(FormKeys.Filter) || {}) as FormControl;
    }

    public get postalCode(): FormControl {
        return (this.formGroup.get(FormKeys.PostalCode) || {}) as FormControl;
    }

    public get resultControl(): FormControl {
        return (this.formGroup.get(FormKeys.Result) || {}) as FormControl;
    }

    public setResultQuestion(): void {
        this.resultQuestion = this.question?.questions.find(question => question.key === FormKeys.Result);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.filterControl.errors;
    }

    public ngOnChanges() {
        this.setResultQuestion();
        this.loading = false;
    }

    public get errorMessage(): string {
        const errorMessage = this.filterControl?.errors?.['message'];
        return errorMessage || this.question.defaultErrorMessage;
    }

    public search(): void {
        this.loading = true;
        this.formGroup.markAsTouched();
        const value = joinFilters([this.filterControl?.value, this.postalCode?.value]);
        this.trigger.emit({ key: this.question.groupName as FormKeys, value });
    }
}
