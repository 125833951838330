import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../services';
import { ExternalTool } from '@nx-customer-apps/shared/enums';

@Component({
    selector: 'vp-monitored-devices',
    templateUrl: './monitored-devices.component.html'
})
export class MonitoredDevicesComponent implements OnInit {
    public url: string;
    constructor(private environment: EnvironmentService) {}
    public ngOnInit() {
        this.url = this.environment.getExternalUrl(ExternalTool.ViGuide);
    }
}
