import { ConsumptionProfile } from '@nx-customer-apps/shared/enums';

export const PARTNER_PORTAL_ROUTES = {
    RELEASE_NOTES: 'support/faq/viguide-planning/release-notes.html',
    GEG_CHECK: 'support/faq/geg-check.html'
};
export const DEFAULT_EXHAUST_GAS_LOSSES = 0;
export const DEFAULT_CONSUMPTION_PROFILE = ConsumptionProfile.NocturnalFall;
export const MAX_VARIANT_QUANTITY = 3;
export const MIN_VARIANT_QUANTITY = 1;
export const CREATE_NEW_VARIANT_ID = 'CREATE_NEW_VARIANT_ID';
export const DEFAULT_BLOCK_TIME = 2;
