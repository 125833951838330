import { Injectable } from '@angular/core';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadFormService } from './heatload-form.service';
import { withLatestFrom, map, merge, Observable, filter, first, mergeMap, EMPTY, of, tap, BehaviorSubject } from 'rxjs';
import { FormKeys, FormName, HeatLoadCalculationMethod } from '@nx-customer-apps/shared/enums';
import { DeviceSelectionFormValue, FormOptions } from '@nx-customer-apps/shared/interfaces';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { HeatloadPrefillMapper } from '../../services';
import { HeatloadService } from './heatload.service';
import { AccordionService } from '../../shared/interfaces';
import { Installation } from '@nx-customer-apps/api-planning-projects';
import { getSelectedInstallation } from '../../shared/utils';
import { joinFilters } from '@nx-customer-apps/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class DeviceSelectionService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private heatloadStore: HeatloadStore,
        private translate: TranslateService,
        private projectStore: ProjectStore,
        private heatloadService: HeatloadService,
        private heatloadFormService: HeatloadFormService
    ) {
        this.initInstallationFilter();
    }

    public questions$ = merge(this.onCalculationMethod$(), this.onInstallations$());
    public isExpanded$ = merge(this.isSubmitedCalculationMethodSameValue$(), this.prefillCompletedByDeviceSelection$());
    /**
     * Sometimes it is possible only to prefill just the installation filter. It is needed to send an event that the prefilling is finished even though it was not a full prefill.
     */
    private isInstallationFilterPrefillFinished$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private installationFilter$: BehaviorSubject<string>;

    /**
     * Here we return a clean UNPREFILLED question set. The question set for just component initialization.
     */
    private onCalculationMethod$() {
        return this.heatloadStore.calculationMethod$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
            map(([method, project, cache]) => ({
                isDeviceData: method?.value === HeatLoadCalculationMethod.Installation,
                project,
                cache
            })),
            mergeMap(state => {
                if (!state.isDeviceData) {
                    return of(null);
                }

                const cacheFilter = state.cache?.deviceSelection?.filter;
                const cachePostalCode = state.cache?.deviceSelection?.postalCode;
                const cacheFilterForInstallations = joinFilters([cacheFilter, cachePostalCode]);

                const projectFilter = (state.project!.viguidePlanningSpecificData?.data as any)?.calculationsData?.deviceSelection?.filter;
                const projectPostalCode = (state.project!.viguidePlanningSpecificData?.data as any)?.calculationsData?.deviceSelection
                    ?.postalCode;
                const projectFilterForInstallations = joinFilters([projectFilter, projectPostalCode]);

                const projectAddressFilter = `${state.project!.address.streetName} ${state.project!.address.houseNumber} ${
                    state.project!.address.city
                }`;
                const projectAddressPostalCode = state.project!.address.postalCode;
                const projectAddressFilterForInstallations = joinFilters([projectAddressFilter, projectAddressPostalCode]);

                const filterForInstallations =
                    cacheFilterForInstallations ||
                    projectFilterForInstallations ||
                    projectAddressFilterForInstallations ||
                    this.installationFilter$?.value;

                if (this.isSimplePrefillNeeded() && filterForInstallations) {
                    this.heatloadStore.getInstallations(filterForInstallations);
                    return EMPTY;
                }

                const projectPrefill = this.prefillMapper.projectToDeviceSelectionPrefill(state.project);
                const cachePrefill = this.prefillMapper.stateToDeviceSelectionPrefill(state.cache?.deviceSelection);
                const formOptions = this.heatloadFormOptionsService.getConnectedDeviceFormOptions({
                    prefill: cachePrefill || projectPrefill
                });

                const questions = this.heatloadFormService.getDeviceSelectionQuestions(formOptions as FormOptions);

                return of(questions);
            })
        );
    }

    /**
     * Here we return a PREFILLED question set. The question set for just component initialization.
     */
    private onInstallations$() {
        return this.heatloadStore.installations$.pipe(
            withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
            map(([allInstallations, project, cache]) => ({
                allInstallations,
                project,
                cache
            })),
            mergeMap(state => {
                if (!this.isSimplePrefillNeeded()) {
                    return EMPTY;
                }

                if (!state.allInstallations) {
                    return of(null);
                }

                const projectAdrressPrefill = this.prefillMapper.projectAddressToDeviceSelectionPrefill(state.project);
                const projectPrefill = this.prefillMapper.projectToDeviceSelectionPrefill(state.project);
                const cachePrefill = this.prefillMapper.stateToDeviceSelectionPrefill(state.cache?.deviceSelection);
                const formOptions = this.heatloadFormOptionsService.getConnectedDeviceFormOptions({
                    prefill: cachePrefill || projectPrefill || projectAdrressPrefill,
                    allInstallations: state.allInstallations
                });

                const questions = this.heatloadFormService.getDeviceSelectionQuestions(formOptions as FormOptions);

                return of(questions);
            })
        );
    }

    /**
     * Indicates a moment when a prefill with form value emition is needed.
     * Usually it changes its value once, initially.
     */
    public isPrefillNeeded(): boolean {
        return (
            this.heatloadService.isRecalculation() &&
            !this.heatloadPrefillService.isPrefilled(FormName.DeviceSelection) &&
            !this.isInstallationFilterPrefillFinished$.value
        );
    }

    /**
     * Indicates a moment when a prefill without form value emition is needed.
     * Usually it changes its value many times. Whenever the form has to be filled again
     * and there is a prefill source available (project data, or store cache data)
     */
    public isSimplePrefillNeeded(): boolean {
        return !this.isInstallationFilterPrefillFinished$.value;
    }

    public getSuccessMessage(formValue: DeviceSelectionFormValue, allInstallations: Installation[]): string {
        const installationId = formValue[FormKeys.ConnectedDevice][FormKeys.Result];
        const installation = getSelectedInstallation(installationId, allInstallations);
        return installation?.device?.name!;
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.DeviceSelection);
    }

    public onPrefillInit(): void {
        this.toggleIsInstallationFilterPrefillFinished(true);
    }

    public onSimplePrefillInit(): void {
        this.toggleIsInstallationFilterPrefillFinished(true);
    }

    public toggleIsInstallationFilterPrefillFinished(value: boolean): void {
        this.isInstallationFilterPrefillFinished$.next(value);
    }

    private isSubmitedCalculationMethodSameValue$() {
        return this.heatloadStore.sameValueSubmited$.pipe(
            map(
                form =>
                    form?.formName === FormName.CalculationMethod &&
                    form.value.methods.standardMethod === HeatLoadCalculationMethod.Installation
            )
        );
    }

    private prefillCompletedByDeviceSelection$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            map(prefillState => {
                const lastAccordionToBePrefilled = FormName.DeviceSelection;
                return prefillState[lastAccordionToBePrefilled].isPrefilled && this.heatloadService.isRecalculation();
            }),
            filter(Boolean),
            first()
        );
    }

    private initInstallationFilter(): void {
        this.projectStore.project$
            .pipe(
                filter(Boolean),
                tap(project => {
                    const projectFilter =
                        (project!.viguidePlanningSpecificData?.data as any)?.calculationsData?.deviceSelection?.filter || '';
                    const projectPostalCode =
                        (project!.viguidePlanningSpecificData?.data as any)?.calculationsData?.deviceSelection?.postalCode || '';
                    const projectFilterForInstallations = joinFilters([projectFilter, projectPostalCode]);

                    this.installationFilter$ = new BehaviorSubject(projectFilterForInstallations);
                })
            )
            .subscribe();
    }
}
