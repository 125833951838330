import { Injectable } from '@angular/core';
import {
    EnergyStandard,
    HeatGeneratorInformation,
    Installation,
    ProjectResponseGetById,
    SystemItem
} from '@nx-customer-apps/api-planning-projects';
import {
    BuildingType,
    CountryCode,
    FormKeys,
    EnergySource,
    ConsumptionProfile,
    HeatLoadCalculationMethod,
    ProjectType,
    RoofType,
    HeatGeneratorType
} from '@nx-customer-apps/shared/enums';
import {
    BuildingHullFormValue,
    DeviceSelectionFormValue,
    DistributionAndScheduleFormValue,
    EnergyCertificateFormValue,
    EnergyConsumptionFormValue,
    EnergySourceOptions,
    EnergyStandardFormValue,
    FormOptions,
    HeatGeneratorDetailsFormValue,
    HeatGeneratorDetailsOptions,
    HeatGeneratorFormValue,
    HeatingEnergySourceState,
    ModernizationsAndInsulationFormValue,
    ModernizationsFormValue,
    RadioQuestionOverride,
    RoofDimensionsFormValue,
    VentilationFormValue
} from '@nx-customer-apps/shared/interfaces';
import { LodashService } from '@nx-customer-apps/shared/services';
import { HeatloadService } from './heatload.service';
import { DEFAULT_EXHAUST_GAS_LOSSES } from '../../shared/utils/constants';
import { ProjectsService } from '../../services';
import { EnumDictionary } from '@nx-customer-apps/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class HeatloadFormOptionsService {
    constructor(private projectsService: ProjectsService, private heatloadService: HeatloadService) {}

    public getEnergyCertificateFormOptions(options: { countryCode: CountryCode; prefill?: EnergyCertificateFormValue }): FormOptions {
        const { prefill, countryCode } = options;

        if (!prefill) {
            return { countryCode };
        }

        const questions = {
            [FormKeys.AirExchangeRate]: { value: prefill?.[FormKeys.AirExchangeRate] },
            [FormKeys.HeatLossTransmission]: { value: prefill?.[FormKeys.HeatLossTransmission] },
            [FormKeys.BuildingVolume]: { value: prefill?.[FormKeys.BuildingVolume] },
            [FormKeys.SurroundingArea]: { value: prefill?.[FormKeys.SurroundingArea] }
        };

        return { countryCode, questions };
    }

    public getLivingAreaFormOptions(options: { prefill?: { livingArea: number }; buildingType: BuildingType; countryCode: CountryCode }) {
        const { prefill, buildingType, countryCode } = options;

        if (!prefill) {
            return { buildingType, countryCode };
        }

        const questions = {
            [FormKeys.LivingArea]: { value: prefill?.[FormKeys.LivingArea] }
        };

        return {
            buildingType,
            countryCode,
            questions
        };
    }

    public getEnergyStandardFormOptions(options: {
        prefill: EnergyStandardFormValue | undefined;
        energyStandards: EnergyStandard[];
    }): FormOptions {
        const { prefill, energyStandards } = options;
        const questions = {
            [FormKeys.EnergyStandards]: { value: prefill?.[FormKeys.EnergyStandards], options: energyStandards }
        };
        return { questions };
    }

    public getModernizationFormOptions(prefill?: ModernizationsFormValue): FormOptions {
        const questions = {
            [FormKeys.BuildingConstructionYear]: { value: prefill?.[FormKeys.BuildingConstructionYear] },
            [FormKeys.RoofModernization]: { value: prefill?.[FormKeys.RoofModernization] },
            [FormKeys.WallsModernization]: { value: prefill?.[FormKeys.WallsModernization] },
            [FormKeys.WindowsModernization]: { value: prefill?.[FormKeys.WindowsModernization] }
        };
        return { questions };
    }

    public getVentilationFormOptions(prefill?: VentilationFormValue): FormOptions {
        const questions = {
            [FormKeys.Ventilation]: { value: prefill?.ventilation }
        };
        return { questions };
    }

    public getBuildingHullFormOptions(project: ProjectResponseGetById, prefill?: BuildingHullFormValue) {
        if (!prefill) {
            return { buildingType: project!.building.buildingType as BuildingType };
        }

        const questions = {
            [FormKeys.BuildingLength]: { value: prefill?.[FormKeys.BuildingDimensions][FormKeys.BuildingLength] },
            [FormKeys.BuildingWidth]: { value: prefill?.[FormKeys.BuildingDimensions][FormKeys.BuildingWidth] },
            [FormKeys.FloorHeight]: { value: prefill?.[FormKeys.BuildingDimensions][FormKeys.FloorHeight] },
            [FormKeys.NumberOfFloors]: { value: prefill?.[FormKeys.BuildingDimensions][FormKeys.NumberOfFloors] },
            [FormKeys.BuildingPosition]: { value: prefill?.[FormKeys.BuildingPosition] },
            [FormKeys.VentilationPresent]: { value: prefill?.[FormKeys.VentilationAndHeating][FormKeys.VentilationPresent] },
            [FormKeys.AtticPresent]: { value: prefill?.[FormKeys.VentilationAndHeating][FormKeys.AtticPresent] },
            [FormKeys.AtticHeated]: { value: prefill?.[FormKeys.VentilationAndHeating][FormKeys.AtticHeated] },
            [FormKeys.BasementPresent]: { value: prefill?.[FormKeys.VentilationAndHeating][FormKeys.BasementPresent] },
            [FormKeys.BasementHeated]: { value: prefill?.[FormKeys.VentilationAndHeating][FormKeys.AtticHeated] }
        };

        return { buildingType: project!.building.buildingType as BuildingType, questions };
    }

    public getModernizationsAndInsulationFormOptions(
        project: ProjectResponseGetById,
        isBasementPresent: boolean,
        prefill?: ModernizationsAndInsulationFormValue
    ) {
        if (!prefill) {
            return {
                projectType: project.projectType as ProjectType,
                basementPresent: isBasementPresent
            };
        }

        const questions = {
            [FormKeys.WindowsConstructionYear]: { value: prefill[FormKeys.WindowsConstructionYear] },
            [FormKeys.BuildingConstructionYear]: { value: prefill[FormKeys.BuildingConstructionYear] },
            [FormKeys.AtticRenovationYear]: { value: prefill[FormKeys.UpperFloorRenovationAndInsulation][FormKeys.AtticRenovationYear] },
            [FormKeys.UpperFloorInsulation]: { value: prefill[FormKeys.UpperFloorRenovationAndInsulation][FormKeys.UpperFloorInsulation] },
            [FormKeys.BasementRenovationYear]: {
                value: prefill[FormKeys.BasementRenovationAndInsulation]?.[FormKeys.BasementRenovationYear]
            },
            [FormKeys.BasementInsulation]: { value: prefill[FormKeys.BasementRenovationAndInsulation]?.[FormKeys.BasementInsulation] },
            [FormKeys.RoofRenovationYear]: { value: prefill[FormKeys.RoofRenovationAndInsulation][FormKeys.RoofRenovationYear] },
            [FormKeys.RoofInsulation]: { value: prefill[FormKeys.RoofRenovationAndInsulation][FormKeys.RoofInsulation] },
            [FormKeys.WallsRenovationYear]: { value: prefill[FormKeys.WallsRenovationAndInsulation][FormKeys.WallsRenovationYear] },
            [FormKeys.WallsInsulation]: { value: prefill[FormKeys.WallsRenovationAndInsulation][FormKeys.WallsInsulation] }
        };

        return {
            projectType: project.projectType as ProjectType,
            basementPresent: isBasementPresent,
            questions
        };
    }

    public getWindowsAndGlazingFormOptions(typeOfGlazingOptions: string[], prefill?: any) {
        return {
            questions: {
                [FormKeys.WindowsPercentageArea]: {
                    value: prefill[FormKeys.WindowsPercentageArea]
                },
                [FormKeys.TypeOfGlazing]: { value: prefill[FormKeys.TypeOfGlazing], options: typeOfGlazingOptions }
            }
        };
    }

    public getRoofTypeFormOptions(prefill?: any) {
        return {
            questions: { [FormKeys.RoofType]: { value: prefill[FormKeys.RoofType] } }
        };
    }

    public getRoofDimensionsFormOptions(prefill: RoofDimensionsFormValue | undefined, roofType: RoofType) {
        const questions = {
            [FormKeys.RoofHeight1]: { value: prefill?.[FormKeys.RoofDimensions][FormKeys.RoofHeight1] },
            [FormKeys.RoofHeight2]: { value: prefill?.[FormKeys.RoofDimensions][FormKeys.RoofHeight2] },
            [FormKeys.HeightOfJamb]: { value: prefill?.[FormKeys.RoofDimensions][FormKeys.HeightOfJamb] },
            [FormKeys.Inclination]: { value: prefill?.[FormKeys.RoofDimensions][FormKeys.Inclination] },
            [FormKeys.Inclination1]: { value: prefill?.[FormKeys.RoofDimensions][FormKeys.Inclination1] },
            [FormKeys.Inclination2]: { value: prefill?.[FormKeys.RoofDimensions][FormKeys.Inclination2] },
            [FormKeys.RoofLength]: { value: prefill?.[FormKeys.RoofDimensions][FormKeys.RoofLength] }
        };
        return { questions, roofType };
    }

    public getEnergySourceFormOptions(options: {
        prefill?: HeatGeneratorFormValue;
        heatGenerators: HeatGeneratorInformation[];
        heatGeneratorDictionary?: EnumDictionary<HeatGeneratorType, string>;
    }) {
        const { prefill, heatGenerators, heatGeneratorDictionary } = options;
        const heatGeneratorOptions = heatGenerators.map(generator => generator.heatGeneratorType);

        const defaultOptions: EnergySourceOptions = {
            questions: {
                [FormKeys.HeatGenerator]: { options: heatGeneratorOptions }
            }
        };

        if (heatGeneratorDictionary) {
            defaultOptions.heatGeneratorDictionary = heatGeneratorDictionary;
        }

        if (!prefill) {
            return defaultOptions;
        }
        const generator = heatGenerators.find(item => item.heatGeneratorType === prefill[FormKeys.HeatGenerator]);

        if (!generator) {
            return defaultOptions;
        }

        const questions: { [key: string]: any } = {
            [FormKeys.HeatGenerator]: { value: prefill[FormKeys.HeatGenerator], options: heatGeneratorOptions }
        };

        const isManySources = generator.energySources.length > 1;

        if (isManySources) {
            const energySourceOptions = generator!.energySources.map(source => source.type);
            questions[FormKeys.EnergySource] = {
                value: prefill[FormKeys.EnergySource],
                options: energySourceOptions
            };
        }

        const energySourceOptions: EnergySourceOptions = { questions };

        if (heatGeneratorDictionary) {
            energySourceOptions.heatGeneratorDictionary = heatGeneratorDictionary;
        }

        return energySourceOptions;
    }

    public getHeatGeneratorDetailsFormOptions(options: {
        buildingType: BuildingType;
        heatingSystems?: SystemItem[];
        energySourceState?: HeatingEnergySourceState;
        prefill?: HeatGeneratorDetailsFormValue;
        buildingConstructionYear?: number;
    }): HeatGeneratorDetailsOptions {
        const { prefill, buildingType, heatingSystems, buildingConstructionYear, energySourceState } = options;

        const defaultOptions: HeatGeneratorDetailsOptions = {
            questions: {
                [FormKeys.InstallationYear]: {}
            },
            buildingType,
            buildingConstructionYear
        };

        if (!prefill) {
            return defaultOptions;
        }

        const boilerTypeOptions = heatingSystems?.map(item => item.type);
        const boilerTypeValue = boilerTypeOptions?.find(item => item === prefill.boilerType);
        const questions: { [key: string]: any } = {
            [FormKeys.InstallationYear]: { value: prefill?.installationYear },
            [FormKeys.BoilerType]: { value: boilerTypeValue, options: boilerTypeOptions }
        };

        if (!prefill?.[FormKeys.BoilerType]) {
            return {
                questions,
                buildingType,
                buildingConstructionYear
            };
        }

        questions[FormKeys.NominalPower] = { value: prefill?.[FormKeys.NominalPower] };

        const selectedInstallationType = heatingSystems?.find(item => item.type === prefill?.[FormKeys.BoilerType]);

        if (selectedInstallationType?.isLowTemperatureOption) {
            questions[FormKeys.LowTemperature] = { value: prefill?.[FormKeys.BoilerTypeSubquestion]?.[FormKeys.LowTemperature] };
        }

        if (selectedInstallationType?.warmWaterByHeatGeneratorOption) {
            questions[FormKeys.WarmWaterByHeatGenerator] = {
                value: prefill?.[FormKeys.BoilerTypeSubquestion]?.[FormKeys.WarmWaterByHeatGenerator]
            };
        }

        const isElectricStorageHeater = this.heatloadService.isHeatGeneratorAnElectricStorageHeater(
            energySourceState?.selectedHeatGenerator!,
            energySourceState?.selectedEnergySource.type as EnergySource
        );

        const formOptions = {
            questions,
            buildingType,
            selectedInstallationType,
            buildingConstructionYear
        };

        if (isElectricStorageHeater) {
            return formOptions;
        }

        if (prefill?.[FormKeys.HeatPumpOperatingMode] && selectedInstallationType?.operatingModes) {
            const operatingModeValue = selectedInstallationType.operatingModes?.find(
                item => item === prefill[FormKeys.HeatPumpOperatingMode]
            );

            questions[FormKeys.HeatPumpOperatingMode] = {
                value: operatingModeValue,
                options: selectedInstallationType.operatingModes
            };
            return formOptions;
        }

        questions[FormKeys.IsKnown] = { value: prefill?.[FormKeys.GasLossExhaustKnown]?.[FormKeys.IsKnown] };

        const isExhaustGasLossDefined =
            !LodashService.isNil(prefill?.[FormKeys.GasLossExhaust]) && prefill?.[FormKeys.GasLossExhaust] !== DEFAULT_EXHAUST_GAS_LOSSES;

        if (isExhaustGasLossDefined) {
            questions[FormKeys.GasLossExhaust] = { value: prefill[FormKeys.GasLossExhaust] };
        }

        return {
            questions,
            buildingType,
            selectedInstallationType,
            buildingConstructionYear
        };
    }

    public getEnergyConsumptionFormOptions(options: {
        buildingType: BuildingType;
        selectedSource?: EnergySource;
        selectedUnit?: string;
        prefill?: EnergyConsumptionFormValue;
    }) {
        const { prefill, selectedSource, selectedUnit, buildingType } = options;

        const defaultOptions = {
            selectedSource,
            selectedUnit,
            buildingType
        };

        if (!prefill) {
            return defaultOptions;
        }

        const questions = {
            [FormKeys.RequiredEnergyPerYear]: { value: prefill[FormKeys.RequiredEnergyPerYear] },
            [FormKeys.NonHeatingElectricityPerYear]: { value: prefill[FormKeys.NonHeatingElectricityPerYear] }
        };

        return {
            questions,
            selectedSource,
            selectedUnit,
            buildingType
        };
    }

    public getConnectedDeviceFormOptions(options?: { allInstallations?: Installation[]; prefill?: DeviceSelectionFormValue }): FormOptions {
        const { allInstallations, prefill } = options || {};

        const resultQuestionOptions =
            allInstallations?.map(installation => {
                const { address, device } = installation;
                return {
                    key: `${device?.name} (${address?.streetName} ${address?.houseNumber}, ${address.postalCode} ${address.city})`,
                    value: installation.id
                };
            }) || [];

        const defualtOptions = {
            questions: {
                [FormKeys.Result]: { options: resultQuestionOptions }
            } as { [key: string]: RadioQuestionOverride }
        };

        if (!prefill) {
            return defualtOptions;
        }
        const resultPrefill = prefill[FormKeys.ConnectedDevice][FormKeys.Result];
        const resultValue = resultQuestionOptions.find(item => item.value === resultPrefill) ? resultPrefill : null;
        const questions = {
            [FormKeys.Result]: { value: resultValue, options: resultQuestionOptions },
            [FormKeys.Filter]: { value: prefill[FormKeys.ConnectedDevice][FormKeys.Filter] },
            [FormKeys.PostalCode]: { value: prefill[FormKeys.ConnectedDevice][FormKeys.PostalCode] }
        };
        return { questions };
    }

    public getHeatingDistributionFormOptions(options: {
        project: ProjectResponseGetById;
        profiles?: ConsumptionProfile[];
        prefill?: DistributionAndScheduleFormValue;
    }) {
        const { prefill, profiles, project } = options;

        const selectedHeatload = this.projectsService.getSelectedHeatload(project)!;
        const defaultOptions = {
            buildingType: project.building.buildingType as BuildingType,
            heatLoadCalculationMethod: selectedHeatload?.method as HeatLoadCalculationMethod,
            questions: {
                [FormKeys.HeatingSchedule]: {
                    options: profiles
                }
            }
        };

        if (!prefill) {
            return defaultOptions;
        }

        const questions = {
            [FormKeys.HeatingFlowTemperature]: { value: prefill.heatingFlowTemperature },
            [FormKeys.DistributionMethod]: { value: prefill.distributionMethod },
            [FormKeys.HeatingSchedule]: { value: prefill.heatingSchedule, options: profiles }
        };

        return {
            questions,
            buildingType: project.building.buildingType as BuildingType,
            heatLoadCalculationMethod: selectedHeatload?.method as HeatLoadCalculationMethod
        };
    }

    private getValue(value: any): any {
        if (LodashService.isNil(value)) {
            return undefined;
        }
        return value;
    }
}
