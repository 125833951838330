export enum FormKeys {
    AirExchangeRate = 'airExchangeRate',
    AtticHeated = 'atticHeated',
    AtticPresent = 'atticPresent',
    AtticRenovationYear = 'atticConstructionYear',
    BasementHeated = 'basementHeated',
    BasementInsulation = 'cellarInsulation',
    BasementPresent = 'basementPresent',
    BasementRenovationAndInsulation = 'basementRenovationAndInsulation',
    BasementRenovationYear = 'cellarConstructionYear',
    BoilerType = 'boilerType',
    BoilerTypeSubquestion = 'boilerTypeSubquestion',
    BuildingConstructionYear = 'buildingConstructionYear',
    BuildingDimensions = 'buildingDimensions',
    BuildingLength = 'buildingLength',
    BuildingPosition = 'buildingPosition',
    BuildingType = 'buildingType',
    BuildingVolume = 'buildingVolume',
    BuildingWidth = 'buildingWidth',
    City = 'city',
    DefinedValue = 'definedValue',
    DistributionMethod = 'distributionMethod',
    EnergySource = 'specificEnergySource',
    EnergyStandards = 'energyStandards',
    FloorHeight = 'floorHeight',
    GasLossExhaust = 'gasLossExhaust',
    GasLossExhaustKnown = 'gasLossExhaustKnown',
    HeatGenerator = 'heatGenerator',
    HeatLossTransmission = 'specificTransmissionHeatLoss',
    HeatPumpOperatingMode = 'heatPumpOperatingMode',
    HeatingFlowTemperature = 'heatingFlowTemperature',
    HeatingSchedule = 'heatingSchedule',
    HeightOfJamb = 'heightOfJamb',
    Inclination = 'inclination',
    Inclination1 = 'inclination1',
    Inclination2 = 'inclination2',
    InstallationYear = 'installationYear',
    IsKnown = 'isKnown',
    LivingArea = 'livingArea',
    Location = 'location',
    LowTemperature = 'lowTemperature',
    Methods = 'methods',
    NominalPower = 'nominalPower',
    NonHeatingElectricityPerYear = 'nonHeatingElectricityPerYear',
    NumberOfFloors = 'numberOfFloors',
    ProjectName = 'projectName',
    ProjectType = 'projectType',
    RequiredEnergyPerYear = 'requiredEnergyPerYear',
    ResidentQuantity = 'residentQuantity',
    RoofDimensions = 'roofDimensions',
    RoofHeight1 = 'roofHeight1',
    RoofHeight2 = 'roofHeight2',
    RoofInsulation = 'roofInsulation',
    RoofLength = 'roofLength',
    RoofModernization = 'roofModernization',
    RoofRenovationAndInsulation = 'roofRenovationAndInsulation',
    RoofRenovationYear = 'roofConstructionYear',
    RoofType = 'roofType',
    StandardMethod = 'standardMethod',
    StreetName = 'streetName',
    HouseNumber = 'houseNumber',
    SurroundingArea = 'surroundingArea',
    TypeOfGlazing = 'typeOfGlazing',
    UpperFloorInsulation = 'atticInsulation',
    UpperFloorRenovationAndInsulation = 'upperFloorRenovationAndInsulation',
    Ventilation = 'ventilation',
    VentilationAndHeating = 'ventilationAndHeating',
    VentilationPresent = 'ventilationPresent',
    WallsInsulation = 'wallsInsulation',
    WallsModernization = 'wallsModernization',
    WallsRenovationAndInsulation = 'wallsRenovationAndInsulation',
    WallsRenovationYear = 'wallsConstructionYear',
    WindowsConstructionYear = 'windowsConstructionYear',
    WindowsModernization = 'windowsModernization',
    WindowsPercentageArea = 'windowsPercentageArea',
    PostalCode = 'postalCode',
    IsOldDeviceUsed = 'isOldDeviceUsed',
    Country = 'countryCode',
    EnergyPrices = 'energyPrices',
    PriceIncrease = 'priceIncrease',
    PartnerNumber = 'partnerNumber',
    EnergyCosts = 'energyCosts',
    ConsumptionProfileForHeating = 'consumptionProfileForHeating',
    HeatingLimitTemperature = 'heatingLimitTemperature',
    InsideTemperatureMax = 'insideTemperatureMax',
    WindowsType = 'windowsType',
    PostalCodeCity = 'postalCodeCity',
    System = 'system',
    WarmWaterByHeatGenerator = 'warmWaterByHeatGenerator',
    IsIncomeLowForSubsidy = 'isIncomeLowForSubsidy',
    IsOwnerOfTheBuilding = 'isOwnerOfTheBuilding',
    IsOwnerMainResidence = 'isOwnerMainResidence',
    IsOldHeatingSystemDefective = 'isOldHeatingSystemDefective',
    NumberOfResidentialUnits = 'numberOfResidentialUnits',
    TargetVariant = 'targetVariant',
    ConnectedDevice = 'connectedDevice',
    Result = 'result',
    Filter = 'filter'
}
