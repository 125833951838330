<div
    [formGroup]="form"
    [ngClass]="{ 'question-margin': !question.validators.length, 'd-none': (question | as : TYPES.TextboxQuestion).hidden }"
>
    <div class="d-flex align-items-center" *ngIf="question.question">
        <h4 class="mb-3">{{ question.question | translate }}</h4>
        <ca-icon
            *ngIf="question.tooltip"
            icon="info-outline"
            class="ca-icon-s-18-p-0-f-18 ms-2 mb-3 vp-pointer"
            matTooltipPosition="above"
            matTooltipClass="ca-tooltip"
            [matTooltip]="question.tooltip | translate"
        ></ca-icon>
    </div>
    <div class="d-flex align-items-center" [class.mb-4]="question.img">
        <img class="description-img me-4" *ngIf="question.img" [src]="question.img" />
        <div>
            <p *ngIf="question.description" class="mb-3">{{ question.description | translate }}</p>
            <ul *ngIf="(question | as : TYPES.TextboxQuestion).descriptionList" class="mb-3 ps-4">
                <li *ngFor="let item of (question | as : TYPES.TextboxQuestion).descriptionList; trackBy: trackBySelf">
                    {{ item | translate }}
                </li>
            </ul>
            <p *ngIf="question.bottomDescription" class="mb-3">{{ question.bottomDescription | translate }}</p>
        </div>
        <div *ngIf="question.content" class="mb-4"><ng-template vpViewHost></ng-template></div>
    </div>
    <div [ngSwitch]="(question | as : TYPES.NotAGroup).controlType">
        <vp-textbox *ngSwitchCase="CONTROL_TYPES.Textbox" [question]="question | as : TYPES.TextboxQuestion" [form]="form"></vp-textbox>
        <vp-radio *ngSwitchCase="CONTROL_TYPES.Radio" [question]="question | as : TYPES.RadioQuestion" [form]="form"></vp-radio>
        <vp-checkbox *ngSwitchCase="CONTROL_TYPES.Checkbox" [question]="question | as : TYPES.CheckboxQuestion" [form]="form"></vp-checkbox>
        <vp-checkbox-set
            *ngSwitchCase="CONTROL_TYPES.CheckboxSet"
            [question]="question | as : TYPES.CheckboxSet"
            [form]="form"
        ></vp-checkbox-set>
        <vp-multi-textbox
            *ngSwitchCase="CONTROL_TYPES.MultiTextbox"
            [question]="question | as : TYPES.MultiTextbox"
            [form]="form"
        ></vp-multi-textbox>
        <vp-custom-answer-radio
            *ngSwitchCase="CONTROL_TYPES.CustomAnswerRadio"
            [question]="question | as : TYPES.CustomAnswerRadio"
            [form]="form"
        >
        </vp-custom-answer-radio>
        <vp-slider
            [form]="form"
            [formControlName]="(question | as : TYPES.SliderQuestion).key"
            *ngSwitchCase="CONTROL_TYPES.Slider"
            [question]="question | as : TYPES.SliderQuestion"
        ></vp-slider>
        <vp-dropdown [form]="form" *ngSwitchCase="CONTROL_TYPES.Dropdown" [question]="question | as : TYPES.Dropdown"></vp-dropdown>
        <vp-search-dropdown
            [formGroup]="form"
            [formControlName]="(question | as : TYPES.SearchDropdown).key"
            *ngSwitchCase="CONTROL_TYPES.SearchDropdown"
            [question]="question | as : TYPES.SearchDropdown"
        ></vp-search-dropdown>
        <vp-addressbox
            [form]="form"
            *ngSwitchCase="CONTROL_TYPES.Addressbox"
            [highlights]="highlights"
            [question]="question | as : TYPES.Addressbox"
        ></vp-addressbox>
        <vp-advanced-search
            (trigger)="questionTrigger.emit($event)"
            [form]="form"
            *ngSwitchCase="CONTROL_TYPES.AdvancedSearch"
            [question]="question | as : TYPES.AdvancedSearch"
        ></vp-advanced-search>
    </div>
</div>
