import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { QuestionBase, TextboxQuestion } from '@nx-customer-apps/shared/utils';

@Component({
    selector: 'vp-textbox',
    templateUrl: './textbox.component.html',
    styleUrl: './textbox.component.scss'
})
export class TextboxComponent {
    @Input() form: FormGroup;
    @Input() question: TextboxQuestion;
    public hint: string | null;

    get control(): AbstractControl {
        return this.form.get((this.question as QuestionBase<any>).key) as AbstractControl;
    }

    get isInvalid(): boolean {
        return this.control?.invalid && this.control?.touched;
    }

    get errorMessage(): string {
        const errorMessage = this.control?.errors?.['message'];
        return errorMessage || (this.question as QuestionBase<any>).defaultErrorMessage;
    }

    public onInput(event: Event): void {
        this.validateOnInput((event as InputEvent).data);
    }

    public onBlur(): void {
        this.hint = null;
    }

    private validateOnInput(value: string | null): void {
        (this.question as TextboxQuestion).hints.forEach(hint => {
            this.hint = hint(value);
        });
    }
}
