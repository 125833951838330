import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { QuestionBase, RadioQuestion, TypesHTMLHelper } from '@nx-customer-apps/shared/utils';
import { slideDown } from '../../animations';

@Component({
    selector: 'vp-radio',
    templateUrl: './radio.component.html',
    styleUrl: './radio.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideDown]
})
export class RadioComponent {
    @Input() form: FormGroup;
    @Input() question: RadioQuestion;

    get control(): AbstractControl {
        return this.form.get((this.question as QuestionBase<any>).key) as AbstractControl;
    }

    get isInvalid(): boolean {
        return this.control?.invalid && this.control?.touched;
    }

    get errorMessage(): string {
        const errorMessage = this.control?.errors?.['message'];
        return errorMessage || (this.question as QuestionBase<any>).defaultErrorMessage;
    }

    public trackByKey(index: number, item: { key: string }): string {
        return item.key;
    }
}
