import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ca-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrl: './dialog-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogHeaderComponent {
    @Input() title?: string;
    @Input() titleElement?: TemplateRef<any>;
    @Input() context: any;
    @Output() closed: EventEmitter<void> = new EventEmitter();

    constructor(private dialogRef: MatDialogRef<any>) {}

    public close() {
        this.closed.emit();
        this.dialogRef.close({ close: true });
    }
}
