<div *ngIf="message" class="message message-{{ message.type }} vp-border-box p-3 d-flex flex-row vp-gap-8 at-message">
    <ca-icon [icon]="message.type | messageTypeToIcon" class="me-2 icon-{{ message.type }}"></ca-icon>
    <div class="d-flex flex-column vp-w-100">
        <div class="d-flex justify-content-between align-items-start">
            <div>
                <span class="ca-bold" *ngIf="message.title" data-testid="message-title">{{ message.title | translate }}</span>
                <div [class.mt-2]="message.title">
                    <p
                        class="mb-0 mat-body-medium"
                        data-testid="message-content"
                        [innerHTML]="message.content | translate | markdownIt"
                    ></p>
                    <ul *ngIf="message.contentList" class="mb-0" data-testid="message-content-list">
                        <li *ngFor="let item of message.contentList">
                            {{ item | translate }}
                        </li>
                    </ul>
                </div>
            </div>
            <ca-icon
                *ngIf="message.closable"
                data-testid="message-close-button"
                icon="cross"
                class="vp-pointer at-close-message"
                (click)="remove.emit(message)"
            ></ca-icon>
        </div>
        <div *ngIf="message.buttonLabel" class="d-flex vp-gap-16 mt-2">
            <a
                *ngIf="message.routerLink || message.action"
                mat-button
                class="ca-button ca-button-no-padding mt-auto px-0 at-action-button"
                data-testid="message-action-button"
                (click)="message.action ? message.action() : null"
                [disabled]="message.disabled"
                disableRipple="true"
                [routerLink]="message.routerLink"
                [queryParams]="message.routerLinkQueryParams"
            >
                <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: message.buttonLabel }"></ng-container>
            </a>
            <a
                *ngIf="message.url"
                disableRipple="true"
                mat-button
                class="ca-button ca-button-no-padding mt-auto px-0 at-action-button"
                data-testid="message-action-link"
                [disabled]="message.disabled"
                [href]="message.url"
            >
                <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: message.buttonLabel }"></ng-container>
            </a>
        </div>
        <div *ngIf="message.links" class="d-flex vp-gap-16 mt-2">
            <ng-container *ngFor="let link of message.links">
                <a
                    *ngIf="link.routerLink"
                    mat-button
                    disableRipple="true"
                    class="ca-button ca-button-no-padding mt-auto px-0 at-action-button"
                    data-testid="message-action-button"
                    [disabled]="link.disabled"
                    [routerLink]="link.routerLink"
                    [queryParams]="link.routerLinkQueryParams"
                >
                    <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: link.label }"></ng-container>
                </a>
                <a
                    *ngIf="link.url"
                    disableRipple="true"
                    mat-button
                    class="ca-button ca-button-no-padding mt-auto px-0 at-action-button"
                    data-testid="message-action-link"
                    [disabled]="link.disabled"
                    [href]="link.url"
                >
                    <ng-container *ngTemplateOutlet="buttonContentElement; context: { $implicit: link.label }"></ng-container>
                </a>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #buttonContentElement let-buttonLabel>
    <span class="button-title">{{ buttonLabel | translate }}</span>
    <ca-icon icon="arrow-right" class="ca-icon-s"></ca-icon>
</ng-template>
