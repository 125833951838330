@if(statusSummary?.next_maintenance; as next) {
<div class="status-page">
    <mat-icon>info</mat-icon>
    <div
        class="status-message"
        [innerHTML]="
            'STATUS_PAGE.MESSAGE'
                | translate
                    : {
                          fromDate: next?.start_date | date : 'dd.MM.YYYY, hh:mm',
                          toDate: next?.end_date | date : 'dd.MM.YYYY, hh:mm'
                      }
                | markdownIt
        "
    ></div>
</div>
}
