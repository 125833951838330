import { CountryCode, ExternalTool, RemoteConfigParameter, ReportConfigurationSection } from '@nx-customer-apps/shared/enums';

export const environment = {
    production: false,
    validateAccess: false,
    applicationName: 'ViGuide Planning',
    baseUrl: 'https://viguide-planning-preview.viessmann.com',
    baseApiUrl: 'https://api-integration.viessmann.com',
    environmentHeaders: {},
    externalTools: {
        [ExternalTool.HeatPumpPlanner]: 'https://heatpump-planner-integration.viessmann.com',
        [ExternalTool.PhotovoltaicPlanner]: 'https://pv-planner-integration.viessmann.com',
        [ExternalTool.QuoteAssist]: 'https://qa-integration.viessmann.com',
        [ExternalTool.PartnerShop]: 'https://shop-test.viessmann.com',
        [ExternalTool.ViGuide]: 'https://viguide-staging.viessmann.com'
    },
    serviceWorker: true,
    environment: 'preview',
    hotJarEnabled: false,
    partnerPortalBaseUrl: 'https://partnerportal-staging.viessmann.com',
    everestApiUrl: 'https://api-integration.viessmann.com',
    accountApiUrl: 'https://account-integration.viessmann.com',
    registerRedirectUrl: 'https://viguide-planning-integration.viessmann.com',
    localeConfig: {
        localStorageKey: 'locale'
    },
    storageKeys: {
        locale: 'locale',
        nextRoute: 'nextRoute',
        lockProjectId: 'lockProjectId',
        onboarding: 'onboarding'
    },
    projectLockConfig: {
        nextAttemptLockDelay: 5000, // 5 seconds
        verifyLockPollingInterval: 1000 * 60 * 1, // 1 minute,
        disabled: true
    },
    csrfTokenConfig: {
        appId: 'viguide-planning-projects',
        localStorageKey: 'csrf_token',
        apiUrl: 'https://api-integration.viessmann.com/auth/v1/saml/csrf'
    },
    meUrl: 'https://api-integration.viessmann.com/users/v1/users/me',
    samlConfig: {
        requestLoginUrl: 'https://api-integration.viessmann.com/saml/sso/request'
    },
    globalHeader: {
        iamBackgroundImage: 'iam_lpi_000001.png'
    },
    remoteConfig: {
        remoteConfigUrl: 'https://api-integration.viessmann.com/remote-config/v3/fetch',
        appId: 'dccdfb2f-3701-4c76-92a2-6b6b69ab1fc1',
        enabled: true,
        defaultConfig: {
            [RemoteConfigParameter.CreatePdfReportButtonVisible]: false,
            [RemoteConfigParameter.CountryDropdownByCountry]: `${CountryCode.DE},${CountryCode.NL},${CountryCode.AT},${CountryCode.CH},${CountryCode.FR},${CountryCode.BE},${CountryCode.CZ},${CountryCode.SK},${CountryCode.PL},${CountryCode.ES}`,
            [RemoteConfigParameter.CountryLanguageSwitcherByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.FR},${CountryCode.BE},${CountryCode.CZ},${CountryCode.SK},${CountryCode.PL},${CountryCode.ES}`,
            [RemoteConfigParameter.QuoteAssistByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.FR},${CountryCode.BE},${CountryCode.CZ},${CountryCode.SK},${CountryCode.ES}`,
            [RemoteConfigParameter.PartnerShopByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.FR},${CountryCode.BE},${CountryCode.CZ},${CountryCode.SK},${CountryCode.ES}`,
            [RemoteConfigParameter.PdfConfiguratorBySection]: `${ReportConfigurationSection.GeneralInformation},${ReportConfigurationSection.HeatPumpPlan},${ReportConfigurationSection.PVPlan},${ReportConfigurationSection.VentilationPlan},${ReportConfigurationSection.FullSystem},${ReportConfigurationSection.LegalNotice}`,
            [RemoteConfigParameter.PdfConfiguratorGeneralInformationBySubsection]: `${ReportConfigurationSection.GeneralProjectData},${ReportConfigurationSection.CurrentSystem},${ReportConfigurationSection.PlannedSystem},${ReportConfigurationSection.EnergyPrices},${ReportConfigurationSection.HeatLoadCalculations},${ReportConfigurationSection.GEGRecommendations}`,
            [RemoteConfigParameter.PdfConfiguratorHeatPumpPlanBySubsection]: `${ReportConfigurationSection.HPSimulationResults},${ReportConfigurationSection.HPFinancialPerformance},${ReportConfigurationSection.AppendixJAZ},${ReportConfigurationSection.HPBillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorPhotovoltaicPlanBySubsection]: `${ReportConfigurationSection.Roofs},${ReportConfigurationSection.PVTechnicalDetails},${ReportConfigurationSection.PVSimulationResults},${ReportConfigurationSection.PVInstallationPlans},${ReportConfigurationSection.PVStaticsAnalysisReport},${ReportConfigurationSection.PVFinancialPerformance},${ReportConfigurationSection.PVBillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorVentilationPlanBySubsection]: ',',
            [RemoteConfigParameter.PdfConfiguratorFullSystemBySubsection]: `${ReportConfigurationSection.FullSystemSimulation},${ReportConfigurationSection.FinancialPerformance},${ReportConfigurationSection.BillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorLegalNoticeBySubsection]: ',',
            [RemoteConfigParameter.DomesticElectricityDayNightTariffByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.BE},${CountryCode.CH},${CountryCode.PL},${CountryCode.FR}`,
            [RemoteConfigParameter.HeatPumpElectricityFlatRateByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.PL}`,
            [RemoteConfigParameter.HeatPumpElectricityDayNightTariffByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.PL}`,
            [RemoteConfigParameter.HeatPumpElectricityBlockTimeByCountry]: `${CountryCode.AT},${CountryCode.CH}`,
            [RemoteConfigParameter.HeatPumpElectricityBlockTimeDisabledByCountry]: `${CountryCode.AT},${CountryCode.CH}`,
            [RemoteConfigParameter.PdfConfiguratorPitchDeckByLocale]: 'en-DE,de-DE,de-AT,de-CH',
            [RemoteConfigParameter.PdfConfiguratorDocumentationTabVisible]: false,
            [RemoteConfigParameter.BoilerPlannerAvailable]: true,
            [RemoteConfigParameter.OnboardingVersion]: '2.22.0'
        }
    },
    statusPageUrl: 'https://api-integration.viessmann.com/status/v1/apps/slt2bwjg6sd2/summary',
    servicePageUrl: 'https://viessmann.statuspage.io'
};
