import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/viguide-planning/src/environments/environment';
import { UserResult } from '@nx-customer-apps/shared/interfaces';
import { ApplicationRoles, IdentityTypes, SystemProfessionalStatus, UserProperties } from '@nx-customer-apps/shared/enums';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private me: UserResult;

    constructor(private http: HttpClient) {}

    /**
     * requests CSRF token from Everest
     * @param appId
     */
    public getCsrfToken(appId: string): Observable<{ token: string }> {
        return this.http.post<{ token: string }>(environment.csrfTokenConfig.apiUrl, { appId });
    }

    /**
     * requests /me data from Everest
     */
    public getLoggedInUserDetails(): Observable<UserResult> {
        return this.http.get<UserResult>(environment.meUrl, { params: { sections: ['identity', 'basic', 'roles', 'sales'] } }).pipe(
            tap(me => {
                this.me = me;
            })
        );
    }

    public isViplanDefault(userResult?: UserResult): boolean {
        const me = userResult || this.me;
        return me.applicationRoles?.includes(ApplicationRoles.Default) || me.applicationRoles?.includes(ApplicationRoles.NevisDefault);
    }

    public isSystemProfi(userResult?: UserResult): boolean {
        const me = userResult || this.me;
        return me.company.systemProfessionalStatus === SystemProfessionalStatus.SystemProfi;
    }

    public getApplicationRole(): ApplicationRoles | undefined {
        if (this.me.applicationRoles?.includes(ApplicationRoles.Admin)) {
            return ApplicationRoles.Admin;
        }

        if (this.me.applicationRoles?.includes(ApplicationRoles.Default)) {
            return ApplicationRoles.Default;
        }

        if (this.me.applicationRoles?.includes(ApplicationRoles.NevisDefault)) {
            return ApplicationRoles.NevisDefault;
        }

        return undefined;
    }

    public isEndCustomer(userResult?: UserResult): boolean | undefined {
        const me = userResult || this.me;
        const identityType = me.properties?.find(property => property.name === UserProperties.IdentityType);
        return identityType && identityType.value === IdentityTypes.EndCustomer;
    }

    public isInstaller(userResult?: UserResult): boolean {
        const me = userResult || this.me;
        const identityType = me.properties?.find(property => property.name === UserProperties.IdentityType);
        return !!(identityType && identityType.value === IdentityTypes.Installer);
    }

    public isEmployee(userResult?: UserResult): boolean {
        const me = userResult || this.me;
        const identityType = me.properties?.find(property => property.name === UserProperties.IdentityType);
        return !!(
            identityType &&
            (identityType.value === IdentityTypes.ViAcc ||
                identityType.value === IdentityTypes.ViExt ||
                identityType.value === IdentityTypes.ViInt)
        );
    }

    public getIdentityType(me: UserResult): IdentityTypes {
        const identityType = me.properties?.find(property => property.name === UserProperties.IdentityType);
        return identityType?.value as IdentityTypes;
    }

    public get userId(): string | null {
        return this.me?.id || null;
    }
}
