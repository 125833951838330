import { ApplicationId } from '@nx-customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const applicationIdToPhraseKey: EnumDictionary<ApplicationId, string> = {
    [ApplicationId.BoilerPlanner]: 'SHARED.EXTERNAL_TOOLS.BOILER_PLANNER',
    [ApplicationId.HeatPumpPlanner]: 'SHARED.EXTERNAL_TOOLS.HEAT_PUMP_PLANNER',
    [ApplicationId.PhotovoltaicPlanner]: 'SHARED.EXTERNAL_TOOLS.PHOTOVOLTAIC_PLANNER',
    [ApplicationId.VentilationPlanner]: 'SHARED.EXTERNAL_TOOLS.VENTILATION_PLANNER',
    [ApplicationId.SystemAdviser]: 'SHARED.EXTERNAL_TOOLS.SYSTEM_ADVISER',
    [ApplicationId.ElectricityPlanner]: 'SHARED.EXTERNAL_TOOLS.ELECTRICITY_PLANNER',
    [ApplicationId.CogenerationCalculator]: 'SHARED.EXTERNAL_TOOLS.COGENERATION_CALCULATOR',
    [ApplicationId.CylinderPlanner]: 'SHARED.EXTERNAL_TOOLS.CYLINDER_PLANNER',
    [ApplicationId.HomePlanner]: 'SHARED.EXTERNAL_TOOLS.HOME_PLANNER',
    [ApplicationId.ViGuidePlanning]: 'SHARED.EXTERNAL_TOOLS.VIGUIDE_PLANNING'
};
