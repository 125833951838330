/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { translationsControllerCreateTranslations } from '../fn/translations-controller/translations-controller-create-translations';
import { TranslationsControllerCreateTranslations$Params } from '../fn/translations-controller/translations-controller-create-translations';
import { translationsControllerDeleteAllTranslations } from '../fn/translations-controller/translations-controller-delete-all-translations';
import { TranslationsControllerDeleteAllTranslations$Params } from '../fn/translations-controller/translations-controller-delete-all-translations';
import { translationsControllerGetTranslations } from '../fn/translations-controller/translations-controller-get-translations';
import { TranslationsControllerGetTranslations$Params } from '../fn/translations-controller/translations-controller-get-translations';


/**
 * Represents API endpoints related to simple translations management for Flow Editor. This is a temporary solution to make Flow Editor more secure.
 */
@Injectable({ providedIn: 'root' })
export class TranslationsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `translationsControllerGetTranslations()` */
  static readonly TranslationsControllerGetTranslationsPath = '/planning-projects/v1-preview/translations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `translationsControllerGetTranslations()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationsControllerGetTranslations$Response(params?: TranslationsControllerGetTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return translationsControllerGetTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `translationsControllerGetTranslations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationsControllerGetTranslations(params?: TranslationsControllerGetTranslations$Params, context?: HttpContext): Observable<void> {
    return this.translationsControllerGetTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `translationsControllerCreateTranslations()` */
  static readonly TranslationsControllerCreateTranslationsPath = '/planning-projects/v1-preview/translations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `translationsControllerCreateTranslations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  translationsControllerCreateTranslations$Response(params: TranslationsControllerCreateTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return translationsControllerCreateTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `translationsControllerCreateTranslations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  translationsControllerCreateTranslations(params: TranslationsControllerCreateTranslations$Params, context?: HttpContext): Observable<void> {
    return this.translationsControllerCreateTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `translationsControllerDeleteAllTranslations()` */
  static readonly TranslationsControllerDeleteAllTranslationsPath = '/planning-projects/v1-preview/translations/{keyPrefix}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `translationsControllerDeleteAllTranslations()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationsControllerDeleteAllTranslations$Response(params: TranslationsControllerDeleteAllTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return translationsControllerDeleteAllTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `translationsControllerDeleteAllTranslations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationsControllerDeleteAllTranslations(params: TranslationsControllerDeleteAllTranslations$Params, context?: HttpContext): Observable<void> {
    return this.translationsControllerDeleteAllTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
