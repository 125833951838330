<div [ngClass]="question.styles" class="d-flex flex-column">
    <div class="d-flex align-items-start mx-0 mb-1">
        <mat-slider class="ca-slider" aria-label="unit(s)" [max]="question.max" [min]="question.min">
            <input matSliderThumb [value]="value" (input)="onSliderInput($event)" />
        </mat-slider>

        <mat-form-field
            [hideRequiredMarker]="true"
            appearance="outline"
            class="col-4 pe-0 ca-form-field ca-form-field-s with-error-message ca-form-field-slider"
            [ngClass]="{ 'with-label': question.label, 'mat-form-field-invalid': validationError && blurred }"
        >
            <mat-label *ngIf="question.label" [attr.for]="question.key">{{ question.label | translate }}</mat-label>
            <input
                (blur)="markAsBlurred()"
                [placeholder]="question.placeholder | translate"
                [id]="question.key"
                [attr.data-testid]="question.key"
                [type]="question.type"
                [formControl]="textField"
                mask="separator.0"
                [suffix]="' ' + (question.suffix | translate)"
                matInput
            />
            <mat-error *ngIf="validationError?.['incorrectValue']">
                <ca-form-error-message [attr.data-testid]="question.key + '-error-message'">
                    {{ 'HEATLOAD.LIVING_AREA.ERRORS.VALUE_RANGE' | translate }}
                </ca-form-error-message>
            </mat-error>
            <mat-error *ngIf="validationError?.['emptyValue']">
                <ca-form-error-message [attr.data-testid]="question.key + '-error-message'">
                    {{ 'HEATLOAD.LIVING_AREA.ERRORS.REQUIRED' | translate }}
                </ca-form-error-message>
            </mat-error>
        </mat-form-field>
    </div>
</div>
