export enum FormName {
    CalculationMethod = 'calculationMethod',
    EnergyCertificate = 'energyCertificate',
    LivingArea = 'livingArea',
    EnergyStandard = 'energyStandard',
    Modernizations = 'modernizations',
    Ventilation = 'ventilation',
    BuildingHull = 'buildingHull',
    RoofType = 'roofType',
    RoofDimensions = 'roofDimensions',
    ModernizationsAndInsulation = 'modernizationsAndInsulation',
    WindowsAndGlazing = 'windowsAndGlazing',
    EnergySource = 'energySource',
    HeatGeneratorDetails = 'heatGeneratorDetails',
    EnergyConsumption = 'energyConsumption',
    HeatingDistribution = 'heatingDistribution',
    Device = 'device',
    System = 'system',
    Building = 'building',
    SubsidyEligibility = 'subsidyEligibility',
    DeviceSelection = 'deviceSelection'
}
