import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStore } from 'apps/viguide-planning/src/app/state/auth/auth.store';
import { AuthService, EnvironmentService, ErrorService } from 'apps/viguide-planning/src/app/services';
import { LodashService, WindowService } from '@nx-customer-apps/shared/services';
import { ApiErrors, RoutePaths } from '@nx-customer-apps/shared/enums';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
    private userId: string;
    private queryParams: { [key: string]: any };

    private getErrorHandler(code: number): (err: HttpErrorResponse, req: any, next: any) => Observable<any> | Observable<never> {
        const handlers: { [key: number]: any } = {
            0: this.handleCorsError.bind(this),
            401: this.handle401Error.bind(this),
            403: this.handle403Error.bind(this),
            404: this.handle404Error.bind(this),
            422: this.handle422Error.bind(this),
            452: (err: HttpErrorResponse) => {
                /**
                 * 452 error => must be or must not be combined case.
                 * error message will be provided in details
                 */
                return throwError(() => err);
            }
        };
        return handlers[code] || this.defaultHandler.bind(this);
    }

    constructor(
        private authStore: AuthStore,
        private envService: EnvironmentService,
        private authService: AuthService,
        private windowService: WindowService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService
    ) {
        this.authStore.userId$.subscribe((userId: string) => (this.userId = userId));
        this.activatedRoute.queryParams.subscribe(params => (this.queryParams = params));
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        request = request.clone();
        const request$ = next.handle(request);
        return request$.pipe(
            catchError((res: HttpErrorResponse) => {
                let handler;
                const error = <HttpErrorResponse>res;

                if (res.error?.error?.code) {
                    // MW custom error
                    handler = this.getErrorHandler(res.error.error.code);
                } else {
                    // other errors
                    handler = this.getErrorHandler(error.status);
                }

                if (handler) {
                    return handler(error, request, next);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    private removeOldToken(): void {
        this.windowService.localStorage.removeItem(this.envService.csrfTokenLocalStorageKey);
    }

    private setToken(token: string, request: { url?: string; clone?: any }): HttpRequest<any> {
        this.windowService.localStorage.setItem(this.envService.csrfTokenLocalStorageKey, token);
        return request.clone({
            setHeaders: {
                Authorization: `CSRF ${token}`
            }
        });
    }

    private handleCorsError(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
        // Show generic error snackbar only for non /saml/csrf requests in case of CORS error
        if (!request.url.endsWith('/saml/csrf')) {
            this.errorService.showSnackbar(ApiErrors.Generic);
        }
        return next.handle(request);
    }

    private handle401Error(err: HttpErrorResponse, request: { url: string }, next: HttpHandler) {
        // 401 on /saml/csrf endpoint means no valid session -> redirect to landing page
        if (request.url.endsWith('/saml/csrf')) {
            // if user was already logged in and the session expires we navigate to IAM page to refresh it
            if (this.userId) {
                this.windowService.localStorage.setItem('token_refresh', 'true');
                this.windowService.changeHref(this.envService.getLoginUrl());

                // if app-link param is present in url, navigate to IAM page to refresh session
            } else if (!LodashService.isNil(this.queryParams['app-link'])) {
                this.windowService.changeHref(this.envService.getLoginUrl());
            } else {
                // otherwise go to landing page
                this.authStore.logout();
            }
            return throwError(() => err);
        }

        this.removeOldToken();
        return this.authService.getCsrfToken(this.envService.appId).pipe(
            switchMap(data => {
                return next.handle(this.setToken(data.token, request));
            }),
            catchError(csrfErr => {
                this.authStore.logout();
                return throwError(() => csrfErr);
            })
        );
    }

    private handle403Error(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
        this.router.navigate([RoutePaths.Error403]);
        return next.handle(request);
    }

    private handle404Error(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
        // 404 on lock or unlock already deleted project - redirect to 404 not found page
        if (request.url.endsWith('/lock') || request.url.endsWith('/unlock')) {
            this.router.navigate([RoutePaths.Error404]);
            return throwError(() => err);
        }
        this.errorService.showSnackbar(err.error?.key);
        return next.handle(request);
    }

    private handle422Error(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
        this.errorService.showSnackbar(err.error?.key, { duration: 0 });
        return next.handle(request);
    }

    private defaultHandler(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
        this.errorService.showSnackbar(err.error?.key);
        return next.handle(request);
    }
}
