import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ControlTypes, FormKeys } from '@nx-customer-apps/shared/enums';
import { Question, TypesHTMLHelper } from '@nx-customer-apps/shared/utils';
import { FormFieldHighlights } from '@nx-customer-apps/shared/interfaces';
import { ViewHostDirective } from '../../directives/view-host.directive';

@Component({
    selector: 'vp-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
    @Input() form: FormGroup;
    @Input() question: Question;
    @Output() questionTrigger: EventEmitter<{ key: FormKeys; value: any }> = new EventEmitter();
    @Input() highlights: FormFieldHighlights = {};
    @ViewChild(ViewHostDirective) contentHost: ViewHostDirective;
    readonly CONTROL_TYPES: typeof ControlTypes = ControlTypes;
    readonly TYPES = TypesHTMLHelper;

    public trackBySelf(index: number, item: string): string {
        return item;
    }

    public ngAfterViewInit() {
        this.setContentHost();
    }

    private setContentHost() {
        if (this.question.content) {
            this.contentHost.viewContainerRef.clear();
            this.contentHost.viewContainerRef.createComponent(this.question.content!);
        }
    }
}
