<a
    mat-button
    [disableRipple]="true"
    class="ca-button ca-button-no-padding"
    data-testid="monitored-devices-link"
    [href]="url"
    target="_blank"
>
    <span>
        {{ 'HEATLOAD.DEVICE_SELECTION.FAQ_LINK' | translate }}
    </span>
    <ca-icon icon="arrow-bounded" class="ca-icon-s-16-p-0-f-16"></ca-icon>
</a>
