import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserResult } from '@nx-customer-apps/shared/interfaces';
import { AuthStore } from 'apps/viguide-planning/src/app/state/auth/auth.store';
import { EnvironmentService } from '../services';
import { WindowService } from '@nx-customer-apps/shared/services';
import { LocaleService } from '../services/locale.service';
import { ApplicationId } from '@nx-customer-apps/shared/enums';
import { LocaleUtils } from '@nx-customer-apps/shared/utils';

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {
    private userData: UserResult;

    constructor(
        private envService: EnvironmentService,
        private localeService: LocaleService,
        private windowService: WindowService,
        private authStore: AuthStore
    ) {
        this.authStore.me$.subscribe((userResult: UserResult) => (this.userData = userResult));
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: this.getHeaders(request),
            withCredentials: this.isCsrfEndpointCall(request)
        });

        if (request.url.includes('/report/pdf') && request.method === 'GET') {
            request = request.clone({ responseType: 'blob' });
        }

        return next.handle(request);
    }

    private getHeaders(request: HttpRequest<any>): any {
        const headers: { [key: string]: string } = {
            'Content-Type': request.url.includes('upload-section') ? 'application/pdf' : 'application/json; charset=utf-8',
            'Accept-Language': this.localeService.getLocaleId() || LocaleUtils.defaultLocale
        };

        const token: string | null = this.getCsrfToken();
        if (token) {
            headers['Authorization'] = `CSRF ${token}`;
        }

        if (!this.isCsrfEndpointCall(request)) {
            headers['X-APPLICATION-ID'] = ApplicationId.ViGuidePlanning;
            headers['X-APPLICATION-COUNTRY-CODE'] = this.localeService.getCountryCode() || LocaleUtils.defaultCountryCode;
            headers['X-CA-APPLICATION-LANG-CODE'] = this.localeService.getLanguageCode() || LocaleUtils.defaultLanguageCode;
            headers['X-CA-APPLICATION-VERSION'] = this.envService.version;
        }

        if (this.isAdminEndpointCall(request)) {
            headers['X-CA-USER-ID'] = this.userData.id;
            headers['X-CA-USER-LOGIN'] = this.userData.loginId;
        }

        if (request.url.includes('reports/pdf')) {
            headers['Accept'] = 'application/json';
        }

        return headers;
    }

    private isAdminEndpointCall(request: HttpRequest<any>): boolean {
        return request.url.toLowerCase().indexOf('/admin/') > 0;
    }

    private isCsrfEndpointCall(request: HttpRequest<any>): boolean {
        return request.url.toLowerCase().indexOf('/saml/csrf') > 0;
    }

    private getCsrfToken(): string | null {
        if (this.envService.csrfTokenLocalStorageKey) {
            return this.windowService.localStorage.getItem(this.envService.csrfTokenLocalStorageKey);
        }
        return null;
    }
}
